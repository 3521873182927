import React, {useEffect, useRef, useState} from 'react';
import {Marker, Popup, useMap} from "react-leaflet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faTimes, faUnlock} from "@fortawesome/free-solid-svg-icons";
import classes from "./MapPage.module.scss";
import Leaflet from "leaflet";
import {DOMAIN} from "../../utils/constants/domain.constans";

const MapMarker = (props) => {
    const {markers, marker, map, removeMarker, isNumberCar, lockBalloon, selectedUser, showModal} = props;
    const mapContainer = useMap();

    const [refReady, setRefReady] = useState(false);
    const [isClicked, setIsClicked] = useState(false);


    let refPopup = useRef();
    let refMarker = useRef();

    useEffect(() => {
        const el = document.querySelectorAll('.avatar-img');

        el.forEach(item => {
            item.addEventListener('click', function (e) {
                showAvatar(e)
            })
        })

    })

    const showAvatar = (e, title) => {
        showModal({
            title: title,
            children: `<img class=${classes.modal_img} src=${e.target.src} alt=${e.target.src}/>`
        })
    }

    useEffect(() => {
        if (selectedUser && marker.userId === selectedUser) {
            mapContainer.setView([marker.coordinate[0], marker.coordinate[1]], 17);
        }
    }, [selectedUser])

    useEffect(() => {
        if (refReady && map && markers.length) {
            loadPopup(markers);
        }
    }, [refReady, map, markers])

    const loadPopup = (markers) => {
        markers.forEach(el => {
            if (refPopup.options.id === el.userId) {
                if (el.balloon.isShowBalloon) {
                    setIsClicked(el.balloon.isShowBalloon);
                    refPopup.openOn(map);
                }
                refMarker.current.removeEventListener('click');
            }
        })
    }

    const getIconCarStatus = (status) => {
        switch (status) {
            case 'OWN':
                return '✅';
            case 'POWER_OF_ATTORNEY':
                return '📝';
            case 'RENT':
                return '🅰️';
            case 'DEPOSIT':
                return '⚠️';
            case 'ARREST':
                return '🛃';
            case 'NO_CONNECTION':
                return '🤷🏻‍';
            default:
                break;
        }
    }

    const repairContent = (marker) => {
        return <div className="repair__wrap">
            <div className="repair__wrap--title">
                <span className="repair__wrap--icon">&#9888;</span>
                <span className="repair__wrap--text-title">РЕМОНТ</span>
            </div>
            <span className="repair__wrap--text">{marker.balloon.repair.text}</span>
        </div>
    }

    const onLock = async (marker) => {
        await lockBalloon(!marker.balloon.isShowBalloon, marker.userId);
    }

    const renderLock = (marker) => {
        return (
            <div style={{position: 'absolute', left: '7px', top: '5px', cursor: 'pointer'}}>
                <FontAwesomeIcon
                    onClick={() => onLock(marker)}
                    icon={marker.balloon.isShowBalloon ? faLock : faUnlock}
                    size="1x"
                />
            </div>
        )
    }

    const initialsContent = (marker) => {
        return <span className={classes.balloon_full_name}>{marker.balloon.last_name} {marker.balloon.first_name}</span>
    }

    const dossierContent = (marker) => {
        return <a className="dossier" href={marker.balloon.dossier.url} target="_blank">досье</a>
    }

    const balloonRemoveContent = (marker) => {
        return <span id={marker.userId} onClick={event => window.removeMarkers(event)} className="balloonRemove">убрать с карты</span>
    }

    const phoneContent = (marker, isNumberCar) => {
        return (isNumberCar ? <div>Телефон: {marker.balloon.phone}, {marker.balloon.number_car.name}</div> :
            <div>Телефон: {marker.balloon.phone}</div>)
    }

    const lastTimeContent = (marker) => {
        return <div>Последнее обновление: {marker.lastTime} минут</div>
    }

    const closeButton = () => {
        return (
            <div style={{position: 'absolute', right: '7px', top: '5px', cursor: 'pointer'}}>
                <FontAwesomeIcon
                    onClick={closePopup}
                    icon={faTimes}
                    size="1x"
                />
            </div>
        )
    }

    const renderBalloonContent = (marker) => {
        return (
            marker.lastTime && marker.lastTime > 0 ?
                (marker.balloon.dossier && marker.balloon.dossier.url ? <div className={classes.balloon_content}>
                        {initialsContent(marker)} {dossierContent(marker)} {balloonRemoveContent(marker)}
                    </div> :
                    <div className={classes.balloon_content}>
                        {initialsContent(marker)} {balloonRemoveContent(marker)}
                    </div>)
                :
                (marker.balloon.dossier && marker.balloon.dossier.url ? <div className={classes.balloon_content}>
                            {initialsContent(marker)} {dossierContent(marker)}
                        </div> :
                        <div className={classes.balloon_content}>
                            {initialsContent(marker)}
                        </div>
                )
        )
    }

    const renderBalloonContentFooter = (marker, isNumberCar) => {
        return (
            marker.lastTime ?
                <div>
                    {phoneContent(marker, isNumberCar)} {lastTimeContent(marker)}
                </div>
                :
                <div>
                    {phoneContent(marker, isNumberCar)}
                </div>
        )
    }

    const renderBalloonContentFooterRepair = (marker, isNumberCar) => {
        return (
            marker.lastTime ?
                <div>
                    {phoneContent(marker, isNumberCar)}
                    {repairContent(marker)}
                    {lastTimeContent(marker)}
                </div> :
                <div>
                    {phoneContent(marker, isNumberCar)} {repairContent(marker)}
                </div>
        )
    }

    const renderIconCaption = (marker) => {
        return (
            `${marker.balloon.number_car ? getIconCarStatus(marker.balloon.number_car.status) : ''} ${marker.balloon.route && marker.balloon.route.name}`
        )
    }

    window.removeMarkers = function (event) {
        removeMarker(event.target.id);
    }

    const renderIconMarker = (marker) => {
        return  marker.balloon.image ?
            `<div style="display: flex; align-items: center; font-size: 14px">
                    <div class="iconMarker" style="border-color: ${marker.balloon.color_marker}">
                        <span>${marker.balloon.rating}</span>
                    </div>
                    <div class="iconMarkerContent">
                        ${renderIconCaption(marker)}
                        <div class="marker_avatar"><img class="avatar-img" src="${DOMAIN}/public/avatars/${marker.balloon.image}" /></div>
                    </div>
                </div>` :
            `<div style="display: flex; align-items: center; font-size: 14px">
                    <div class="iconMarker" style="border-color: ${marker.balloon.color_marker}">
                        <span>${marker.balloon.rating}</span>
                    </div>
                    <div class="iconMarkerContent">${renderIconCaption(marker)}</div>
                </div>`
    }

    const closePopup = () => {
        setIsClicked(false);
        refPopup._close();
    }

    return (
        <Marker
            ref={refMarker}
            map={map}
            position={[...marker.coordinate]}
            id={marker.userId}
            eventHandlers={{
                preclick: (event) => {
                    if (isClicked && !marker.balloon.isShowBalloon) {
                        setIsClicked(false);
                        event.target._popup._close();
                    } else if (!marker.balloon.isShowBalloon) {
                        setIsClicked(true);
                        event.target._popup.openOn(map);
                    }

                },
            }}
            icon={Leaflet.divIcon({html: renderIconMarker(marker)})}
        >
            <Popup
                id={marker.userId}
                ref={(r) => {
                    refPopup = r;
                    setRefReady(true);
                }}
                autoClose={false}
                closeOnClick={false}
                closeButton={false}>
                <div>
                    {!marker.balloon.isShowBalloon && closeButton()}
                    {renderLock(marker)}
                    {renderBalloonContent(marker)}
                    {marker.balloon.repair ? renderBalloonContentFooterRepair(marker, isNumberCar) : renderBalloonContentFooter(marker, isNumberCar)}
                </div>
            </Popup>
        </Marker>
    );
};

export default MapMarker;
