import React, {useEffect, useState} from "react";
import classes from "./Edit.module.scss";
import {connect} from "react-redux";
import {editAdmin} from "../../store/user/action";
import {MAP_ROUTE} from "../../utils/constants/routes.constants";
import {ERROR} from "../../store/user/actionType";
import Form from "../../components/form/Form"
import InputForm from "../../components/form/input/InputForm";

const Edit = (props) => {
    const {currentUser, editAdmin, getError} = props;

    const [data, setData] = useState({});
    const [pass, setPass] = useState('');
    const [phone, setPhone] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [patronymic, setPatronymic] = useState('');
    const [email, setEmail] = useState(null);

    useEffect(() => {
        if (Object.keys(currentUser).length !== 0) {
            if (currentUser.phone) setPhone(`+${currentUser.phone}`);
            if (currentUser.last_name) setLastName(currentUser.last_name);
            if (currentUser.password) setPass(currentUser.password);
            if (currentUser.first_name) setFirstName(currentUser.first_name);
            if (currentUser.patronymic) setPatronymic(currentUser.patronymic);
            if (currentUser.email) setEmail(currentUser.email);
        }
    }, [currentUser])

    useEffect(() => {
        setData({
            id: currentUser.id,
            email: email,
            password: pass,
            phone: !phone ? '1' : phone,
            last_name: lastName,
            first_name: firstName,
            patronymic: patronymic
        });
    }, [pass, phone, lastName, firstName, patronymic, email]);


    return (
        <div className={classes.edit}>
            <h2 className={classes.title}>Редактировать профиль</h2>
            <Form data={data}
                  userData={currentUser}
                  path={MAP_ROUTE}
                  requestSubmit={editAdmin}>
                <InputForm name="phone"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите номер телефона"
                           label="Телефон"
                           isPhone
                           country="RU"
                           value={phone}
                           onChange={e => setPhone(e)}
                />
                <InputForm name="email"
                           style={{minWidth: "49%"}}
                           type="email"
                           placeholder="Введите email"
                           label="Email"
                           value={email ? email : ''}
                           onChange={e => setEmail(e.target.value)}
                />
                <InputForm name="last-name"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите фамилию"
                           label="Фамилия"
                           value={lastName}
                           onChange={e => setLastName(e.target.value)}
                />
                <InputForm name="first-name"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите имя"
                           label="Имя"
                           value={firstName}
                           onChange={e => setFirstName(e.target.value)}
                />
                <InputForm name="patronymic"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите отчество"
                           label="Отчество"
                           value={patronymic}
                           onChange={e => setPatronymic(e.target.value)}
                />
                <InputForm name="pass"
                           style={{minWidth: "49%"}}
                           type="password"
                           placeholder="Введите новый пароль"
                           label="Изменить пароль"
                           value={pass}
                           onChange={e => setPass(e.target.value)}
                />
            </Form>
        </div>
    );
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        editAdmin: (data) => dispatch(editAdmin(data)),
        getError: (payload) => dispatch({type: ERROR, payload: payload})
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Edit);
