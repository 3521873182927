import React from "react";
import {Routes, Route, Navigate} from "react-router-dom";
import {authRoutes, publicRoutes} from "../../routes";
import {MAP_ROUTE, LOGIN_ROUTE} from "../../utils/constants/routes.constants";
import {connect} from "react-redux";
import Privacy from "../../views/privacy/Privacy";
import Support from "../../views/support/Support";

const AppRouter = (props) => {
    const {isAuth} = props;
    return (
        <Routes>
            {isAuth && authRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={Component} exact/>
            )}
            {!isAuth && publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={Component} exact/>
            )}
            <Route path={'/privacy-policy'} element={<Privacy />} />
            <Route path={'/support'} element={<Support />} />
            {isAuth && <Route path="*" element={<Navigate to={MAP_ROUTE}/>} exact />}
            {!isAuth && <Route path="*" element={<Navigate to={LOGIN_ROUTE}/>} exact />}
        </Routes>
    );
};

const mapStateToProps = state => ({
    isAuth: state.user.isAuth
});

export default connect(mapStateToProps, null)(AppRouter);
