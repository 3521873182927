import React, {useRef, useState} from 'react';
import classes from "./ShipmentStatistics.module.scss";
import ShipmentStatisticsItem from "./shipment-statistics-item/ShipmentStatisticsItem";
import {addCustomShipmentStatistic, getShipmentStatistic} from "../../store/shipment-statistics/action";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faLongArrowAltDown,
    faLongArrowAltUp
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
import Button from "../../components/button/Button";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {useOutsideCalendar} from "../../hooks/useOutsideCalendar";
import { confirmAlert } from 'react-confirm-alert';

const paramsInitial = {
    sort_name: 'count',
    sort_type: 'desc',
}

const periods = [
    {
        id: 1,
        type: 'year',
        name: 'год',
        from: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'day').format('YYYY-MM-DD')
    },
    {
        id: 2,
        type: 'six_months',
        name: '6 месяцев',
        from: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'day').format('YYYY-MM-DD')
    },
    {
        id: 3,
        type: 'three_months',
        name: '3 месяца',
        from: dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'day').format('YYYY-MM-DD')
    },
    {
        id: 4,
        type: 'month',
        name: '1 месяц',
        from: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        to: dayjs().add(1, 'day').format('YYYY-MM-DD')
    }
]

const ShipmentStatistics = (props) => {
    const {shipment_statistic, isLoading, getShipmentStatistic, addCustomShipmentStatistic} = props;
    const wrapperRef = useRef(null);

    const [params, setParams] = useState(paramsInitial);
    const [periodType, setPeriodType] = useState(null);
    const [calendarType, setCalendarType] = useState("RANGE");
    const [calendarPeriod, setCalendarPeriod] = useState(new Date());
    const [shipmentCalendarDate, setShipmentCalendarDate] = useState(new Date());
    const [isShowCalendar, setIsShowCalendar] = useState(false);
    const [shipmentUserId, setShipmentUserId] = useState(null);

    useOutsideCalendar(wrapperRef, setIsShowCalendar);

    const onShow = async () => {
        setCalendarType("RANGE");
        if (periodType !== 'calendar') {
            const foundPeriod = periods.find(period => period.type === periodType)
            setParams(prev => ({...prev, from: foundPeriod.from, to: foundPeriod.to}))
            await getShipmentStatistic({...params, from: foundPeriod.from, to: foundPeriod.to});
        } else {
            if (!calendarPeriod[1]) {
                setParams(prev => ({...prev, from: dayjs(calendarPeriod[0]).format('YYYY-MM-DD'), to: dayjs(calendarPeriod[0]).add(1, 'day').format('YYYY-MM-DD')}))
                await getShipmentStatistic({...params, from: dayjs(calendarPeriod[0]).format('YYYY-MM-DD'), to: dayjs(calendarPeriod[0]).add(1, 'day').format('YYYY-MM-DD')});
            } else {
                setParams(prev => ({...prev, from: dayjs(calendarPeriod[0]).format('YYYY-MM-DD'), to: dayjs(calendarPeriod[1]).format('YYYY-MM-DD')}))
                await getShipmentStatistic({...params, from: dayjs(calendarPeriod[0]).format('YYYY-MM-DD'), to: dayjs(calendarPeriod[1]).add(1, 'day').format('YYYY-MM-DD')});
            }
        }
    }

    const onSort = async (name) => {
        let type = 'desc';
        if (name === params.sort_name) {
            if (params.sort_type !== 'asc') {
                type = 'asc'
            }
        }

        setParams(prev => ({...prev, sort_name: name, sort_type: type}))
        await getShipmentStatistic({...params, sort_name: name, sort_type: type});
    }

    const onChange = (e) => {
        setPeriodType('calendar')
        setCalendarPeriod(e)
    }

    const onChangeShipmentCalendar = async (e) => {
        setShipmentCalendarDate(e);
        setIsShowCalendar(false);
        confirmAlert({
            title: 'Подтвердите добавление',
            message: 'Вы уверены что хотите сделать это?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {
                        addCustomShipmentStatistic({userId: shipmentUserId, date: dayjs(e).format('YYYY-MM-DD')});
                        setShipmentUserId(null);
                    }
                },
                {
                    label: 'Нет'
                }
            ]
        });
    }

    const handleOnShowShipmentCalendar = (userId) => {
        setShipmentUserId(userId);
        setCalendarType("ADD_SHIPMENT");
        setIsShowCalendar(true);
    }

    return (
        <div className={`${classes.shipmentStatistics} wrapper`}>
            <div className={classes.table__wrapper}>
                <div className={classes.shipmentStatistics__period_wrapper}>
                    <div>
                        <div className={periodType === 'calendar' ?
                            `${classes.shipmentStatistics__period_item} ${classes.shipmentStatistics__period_item_active}` :
                            classes.shipmentStatistics__period_item}
                             onClick={() => setIsShowCalendar(!isShowCalendar)}
                        >
                            Выбрать в календаре
                        </div>
                    </div>
                    {periods.map(period => {
                        return <div
                            className={period.type === periodType ?
                                `${classes.shipmentStatistics__period_item} ${classes.shipmentStatistics__period_item_active}` :
                                classes.shipmentStatistics__period_item}
                            key={period.id}
                            onClick={() => setPeriodType(period.type)}>
                            {period.name}
                        </div>
                    })}
                </div>
                <Button
                    className={classes.shipmentStatistics__button}
                    disabled={!periodType}
                    name="Показать"
                    onClick={onShow}
                    isLoading={isLoading}
                />
                <table className={classes.table}>
                    <thead>
                    <tr className={classes.table__head}>
                        <td className={classes.table__column} onClick={() => onSort('last_name')}>
                            ФИО {params.sort_name === 'last_name' && params.sort_type === 'desc' ?
                            <FontAwesomeIcon icon={faLongArrowAltUp} size="1x" /> :
                            <FontAwesomeIcon icon={faLongArrowAltDown} size="1x" />}
                        </td>
                        <td className={classes.table__column} onClick={() => onSort('count')}>
                            Колличество рейсов {params.sort_name === 'count' && params.sort_type === 'desc' ?
                            <FontAwesomeIcon icon={faLongArrowAltUp} size="1x" /> :
                            <FontAwesomeIcon icon={faLongArrowAltDown} size="1x" />}
                        </td>
                        <td className={classes.table__column}></td>
                    </tr>
                    </thead>
                    <tbody>
                    {shipment_statistic.length ? shipment_statistic.map(item =>
                            <ShipmentStatisticsItem key={item.shipmentStatistic.id} item={item} onShowCalendar={handleOnShowShipmentCalendar} />) :
                        <tr><td></td></tr>
                    }
                    </tbody>
                </table>
            </div>
            {isShowCalendar && <div className={classes.shipmentStatistics__calendar_block}>
                <div className={classes['shipmentStatistics__calendar_block--bg']}></div>
                <div ref={wrapperRef}>
                    {calendarType === "RANGE" && <Calendar
                        onChange={onChange}
                        value={calendarPeriod}
                        allowPartialRange={true}
                        maxDate={new Date()}
                        selectRange={true}
                    />}
                    {calendarType === "ADD_SHIPMENT" && <Calendar
                        onClickDay={onChangeShipmentCalendar}
                        value={shipmentCalendarDate}
                        maxDate={new Date()}
                    />}
                </div>
            </div>}
        </div>
    );
};

const mapStateToProps = state => ({
    shipment_statistic: state.shipment_statistic.shipment_statistic,
    isLoading: state.shipment_statistic.isLoading,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getShipmentStatistic: (params) => dispatch(getShipmentStatistic(params)),
        addCustomShipmentStatistic: (payload) => dispatch(addCustomShipmentStatistic(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStatistics);
