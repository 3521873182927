import {SET_SELECTED_USER, SET_SOCKET_MESSAGE} from "./actionType";
import {Api} from "../../services/api";
import {Resource} from "../../services/ApiResources";
import {socket} from "../../services/connectedSocket"
import {GET_ALL_USERS} from "../user/actionType";

const getModelBalloon = (arr1, arr2) => {
    return arr2.map(el => {
        const withCurrentId = arr1.filter(item1 => item1['id'] === el['userId']);
        if (!withCurrentId.length) return null;
        let str;
        if (withCurrentId[0].repair) {
            const arrStrings = withCurrentId[0].repair.text.split('\n');
            str = {text: arrStrings.map((str, i) => <p key={`p_${i}`}>{str}</p>)};
        }
        const balloon = {...withCurrentId[0], repair: str}
        return {...el, balloon}
    }).filter(Boolean)
}

export const addMarker = () => {
    return async (dispatch, getState) => {
        let res = await Api.GET({url: Resource.ALL_USERS});
        const users_all = res.rows;
        dispatch({type: GET_ALL_USERS, payload: users_all});
        await socket.on('update_user', async (data) => {
            let res = await Api.GET({url: Resource.ALL_USERS});
            const users_all = res.rows;
            if (data) {
                dispatch({type: GET_ALL_USERS, payload: users_all});
                await socket.emit('message', {isEdit: true});
            }
        });
        await socket.emit('message', {role: 'ADMIN'});
        await socket.on('message', async (data) => {
            const {all_users} = getState().user;
            const result = getModelBalloon(all_users, data);
            dispatch({type: SET_SOCKET_MESSAGE, payload: result});
        });
    };
};

export const removeMarker = (id) => {
    return async () => {
        await socket.emit('remove', {role: 'ADMIN', id});
    };
};

export const lockBalloon = (isShowBalloon, id) => {
    return async () => {
        await Api.POST(Resource.LOCK_BALLOON, {isShowBalloon, id});
        await socket.emit('update_user', {id, type: 'EDIT'});
    };
}

export const selectUser = (id) => {
    return async (dispatch) => {
        dispatch({type: SET_SELECTED_USER, payload: id});
    };
}
