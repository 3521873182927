import React, {useEffect, useState} from 'react';
import classes from "./Pagination.module.scss";

const Pagination = (props) => {
    const {onClickPage, onClickSize, totalPages, totalUsers, params} = props;

    const [activeLinkPage, setActiveLinkPage] = useState(null);
    const [activeLinkSize, setActiveLinkSize] = useState(null);

    useEffect(() => {
        setActiveLinkPage(params.page);
        setActiveLinkSize(params.pageSize);
    }, [params]);

    const handlePage = async (e) => {
        setActiveLinkPage(+e.target.id);
        await onClickPage(e);
    }

    const handleSize = async (e) => {
        setActiveLinkSize(+e.target.id);
        await onClickSize(e);
    }

    const renderPaginationPage = () => {
        const arrPage = [];
        for (let i = 0; i < totalPages; i++) {
            arrPage.push({id: i, text: i + 1});
        }

        const lastPage = arrPage.slice(-1);

        const pages = lastPage[0]?.id > activeLinkPage ?
            arrPage.slice(activeLinkPage !== 0 && activeLinkPage - 1, activeLinkPage !== 0 ? activeLinkPage + 2 : activeLinkPage + 3) :
            arrPage.slice(-3);

        const isShowLastPage = lastPage[0]?.id > (activeLinkPage === 0 ? activeLinkPage + 2 : activeLinkPage + 1);
        return (
            <div className={`${classes.pagination__wrap} ${classes.center}`}>
                {pages.length && pages.map(item =>
                    <span className={item.id === activeLinkPage ? `${classes.active} ${classes.pagination__text}` : classes.pagination__text}
                        onClick={e => handlePage(e)}
                        key={item.id}
                        id={item.id}>{item.text}</span>
                )
                }
                {isShowLastPage && <span>...</span>}
                {isShowLastPage && lastPage.map(item =>
                    <span className={item.id === activeLinkPage ? `${classes.active} ${classes.pagination__text}` : classes.pagination__text}
                        onClick={e => handlePage(e)}
                        key={item.id}
                        id={item.id}>{item.text}</span>
                )
                }
            </div>
        )
    }

    const renderPaginationPageSize = () => {
        const arrSize = [5, 10, 20, 50, 100];

        return (
            <div className={`${classes.pagination__wrap} ${classes.right}`}>
                <span>Показывать: </span>
                {arrSize.length && arrSize.map(item =>
                    <span className={activeLinkSize === item ? `${classes.pagination__text} ${classes.active}` : classes.pagination__text} onClick={e => handleSize(e)} id={item} key={item}>{item}</span>)
                }
            </div>
        )
    }


    return (
        <div className={classes.pagination}>
            <div className={`${classes.pagination__wrap} ${classes.left}`}>
                <p>Всего страниц: <span className={classes.main_color}>{totalPages}</span></p>
                <p>Всего курьеров: <span className={classes.main_color}>{totalUsers}</span></p>
            </div>
            {renderPaginationPage()}
            {renderPaginationPageSize()}
        </div>
    );
}

export default Pagination;
