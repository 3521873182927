import React from 'react';
import classes from "./Textarea.module.scss";

const Textarea = (props) => {
    const {label, name, placeholder, value, onChange, style} = props;
    return (
        <div className={classes.wrap} style={style}>
            <label htmlFor={name} className={classes.wrap__label}>{label}</label>
            <textarea
                className={classes.wrap__textarea}
                name={name}
                id={name}
                cols="30"
                rows="10"
                placeholder={placeholder}
                value={value}
                onChange={e => onChange(e)}
            />
        </div>
    );
};

export default Textarea;
