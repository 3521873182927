export const SET_IS_AUTH = 'SET_IS_AUTH';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const DELETE_USER = 'DELETE_USER';
export const LOGOUT = 'LOGOUT';
export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const GET_USERS = 'GET_USERS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const IS_LOADING = 'IS_LOADING';
export const TOTAL_PAGES = 'TOTAL_PAGES';
export const TOTAL_USERS = 'TOTAL_USERS';
export const PARAMS = 'PARAMS';
export const IS_FINISHED_ROUTES = 'IS_FINISHED_ROUTES';
export const CLEAR_FINISHED_THIS_USER = 'CLEAR_FINISHED_THIS_USER';
export const CLEAR_FINISHED_ROUTES_ALL = 'CLEAR_FINISHED_ROUTES_ALL';
export const SORT_FIELD = 'SORT_FIELD';
export const SEARCH_USER = 'SEARCH_USER';
export const CHANGE_DIRECTION = 'CHANGE_DIRECTION';
