import {
    ADD_SHIPMENT_STATISTICS,
    CHANGE_LOADING,
    DELETE_SHIPMENT_STATISTICS,
    GET_SHIPMENT_STATISTICS,
    GET_SHIPMENT_STATISTICS_USER
} from "./actionType";


const initialState = {
    shipment_statistic: [],
    shipment_statistic_user: [],
    isLoading: false,
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_SHIPMENT_STATISTICS:
            return {
                ...state,
                shipment_statistic: payload
            };
        case GET_SHIPMENT_STATISTICS_USER:
            return {
                ...state,
                shipment_statistic_user: payload
            };
        case ADD_SHIPMENT_STATISTICS: {
            const foundShipmentStatistic = state.shipment_statistic.find(item => item.shipmentStatistic.userId === payload.userId);

            const shipmentStatisticIndex = state.shipment_statistic.findIndex((e) => e.shipmentStatistic.id === foundShipmentStatistic.shipmentStatistic.id)
            state.shipment_statistic[shipmentStatisticIndex] = {...foundShipmentStatistic, count: +foundShipmentStatistic.count + 1};

            return {
                ...state
            };
        }
        case DELETE_SHIPMENT_STATISTICS: {
            const filteredShipmentStatisticUser = state.shipment_statistic_user.filter(item => +item.id !== +payload)
            return {
                ...state,
                shipment_statistic_user: filteredShipmentStatisticUser
            };
        }
        case CHANGE_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return { ...state };
    }
};
