import {SHOW_MODAL, HIDE_MODAL} from "./actionType";

export const showModal = (data) => {
    return async (dispatch) => {
        dispatch({type: SHOW_MODAL, payload: data});
    }
}

export const hideModal = () => {
    return async (dispatch) => {
        dispatch({type: HIDE_MODAL});
    }
}
