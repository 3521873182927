import React from 'react';
import classes from "./ListProductItem.module.scss";
import {multiplication} from "../../../../../../utils/helpers/summHelper"

const ListProductItem = (props) => {
    const {product} = props;

    return (
        <tr>
            <td>{product.name}</td>
            <td>{product.amount}</td>
            <td>{Number(product.sum).toLocaleString()}</td>
            <td className={classes["list-product-item--bold"]}>{multiplication(product.amount, product.sum).toLocaleString()} &#8381;</td>
        </tr>
    );
};

export default ListProductItem;
