import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Form from "../../../components/form/Form";
import {COURIERS_ROUTE} from "../../../utils/constants/routes.constants";
import classes from "./EditCourier.module.scss";
import {connect} from "react-redux";
import {editCourier, getUsers, uploadAvatar} from "../../../store/user/action";
import {ERROR} from "../../../store/user/actionType";
import InputForm from "../../../components/form/input/InputForm";
import Select from "../../../components/form/select/Select";
import ReactStars from "react-rating-stars-component";
import Textarea from "../../../components/form/textarea/Textarea";
import UploadImage from "../../../components/layout/uploadImage/UploadImage";

const EditCourier = (props) => {
    const {users, editCourier, getError, getUsers, uploadAvatar} = props;
    const params = useParams();

    const user = users.reduce((acc, item) => {
        if (item.id === +params.id) acc = item;
        return acc
    }, {});

    const [data, setData] = useState({});
    const [pass, setPass] = useState('');
    const [phone, setPhone] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [rating, setRating] = useState(1);
    const [patronymic, setPatronymic] = useState('');
    const [route, setRoute] = useState('');
    const [routeError, setRouteError] = useState('');
    const [numberCar, setNumberCar] = useState('');
    const [dossierUrl, setDossierUrl] = useState('');
    const [colorMarker, setColorMarker] = useState('#1e98fe');
    const [statusCar, setStatusCar] = useState('NO_CONNECTION');
    const [repair, setRepair] = useState('');
    const [cropDataFile, setCropDataFile] = useState(null);

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            if (user.phone) setPhone(`+${user.phone}`);
            if (user.last_name) setLastName(user.last_name);
            if (user.password) setPass(user.password);
            if (user.first_name) setFirstName(user.first_name);
            if (user.patronymic) setPatronymic(user.patronymic);
            if (user.rating) setRating(user.rating);
            if (user.route) setRoute(user.route.name);
            if (user.number_car) {
                setNumberCar(user.number_car.name);
                setStatusCar(user.number_car.status);
            }
            if (user.dossier && user.dossier.url) setDossierUrl(user.dossier.url);
            if (user.color_marker) setColorMarker(user.color_marker);
            if (user.repair) setRepair(user.repair.text);
        }
    }, [user])

    useEffect(() => {
        setData({
            id: user.id,
            password: pass,
            phone: !phone ? '1' : phone,
            last_name: lastName,
            first_name: firstName,
            patronymic: patronymic,
            route: route,
            number_car: {
                name: numberCar,
                status: statusCar
            },
            dossier: {
                url: dossierUrl ? dossierUrl : null
            },
            color_marker: colorMarker && colorMarker,
            repair: repair,
            rating: rating
        });
    }, [pass, phone, lastName, firstName, rating, patronymic, route, numberCar, dossierUrl, colorMarker, statusCar, repair]);

    const submit = async (data) => {
        setRouteError('');
        if (route.length < 1) return setRouteError('Не может быть пустым');
        let newData = data;
        if (cropDataFile) {
            const formData = new FormData();

            formData.append(
                "img",
                cropDataFile,
                cropDataFile.name
            );

            const fileName = await uploadAvatar(formData);
            newData = {...newData, image: fileName}
        }
        return await editCourier(newData);
    }

    useEffect(() => {
        getUsers();
    }, []);

    const onChangePass = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            setPass(e.target.value.slice(0, 6));
        }
    }

    const ratingChanged = (newRating) => {
        setRating(newRating);
    };

    const getFile = (e) => {
        setCropDataFile(e)
    }

    return (
        <div className={classes.edit__courier}>
            <h2 className={classes.title}>Редактировать курьера</h2>
            {user && <Form data={data}
                                  path={COURIERS_ROUTE}
                                  requestSubmit={submit}>
                <InputForm name="phone"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите номер телефона"
                           label="Телефон"
                           isPhone
                           country="RU"
                           value={phone}
                           onChange={e => setPhone(e)}
                />
                <InputForm name="last-name"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите фамилию"
                           label="Фамилия"
                           value={lastName}
                           onChange={e => setLastName(e.target.value)}
                />
                <InputForm name="first-name"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите имя"
                           label="Имя"
                           value={firstName}
                           onChange={e => setFirstName(e.target.value)}
                />
                <InputForm name="patronymic"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите отчество"
                           label="Отчество"
                           value={patronymic}
                           onChange={e => setPatronymic(e.target.value)}
                />
                <InputForm name="pass"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите новый пароль"
                           label="Пароль"
                           value={pass}
                           onChange={onChangePass}
                />
                <InputForm name="route"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите название рейса"
                           label="Рейс"
                           error={routeError}
                           value={route}
                           onChange={e => setRoute(e.target.value)}
                />
                <InputForm name="number-car"
                           style={{minWidth: "49%"}}
                           type="text"
                           placeholder="Введите марку/номер тягача/пункт назначения"
                           label="Марка/Номер тягача/Пункт назначения"
                           value={numberCar}
                           onChange={e => setNumberCar(e.target.value)}
                />
                <InputForm name="dossier-url"
                           style={{minWidth: "49%"}}
                           type="text"
                           label="Ссылка досье"
                           placeholder="Введите url досье"
                           value={dossierUrl}
                           onChange={e => setDossierUrl(e.target.value)}
                />
                <Select name="color-marker"
                        style={{minWidth: "49%"}}
                        label="Цвет маркера"
                        value={colorMarker}
                        onChange={e => setColorMarker(e.target.value)}>
                    <option value="#1e98fe">Синий</option>
                    <option value="yellow">Желтый</option>
                    <option value="red">Красный ИКРА</option>
                </Select>
                <div className={classes.stars_wrap}>
                    <span>Рейтинг</span>
                    <ReactStars
                        classNames={classes.stars}
                        onChange={ratingChanged}
                        count={10}
                        size={30}
                        value={user && user.rating ? user.rating : 0}
                    />
                </div>
                <div className={classes.stars_wrap}>
                    <Textarea name="text"
                              type="text"
                              placeholder="Введите текст"
                              label="Ремонт"
                              value={repair}
                              onChange={e => setRepair(e.target.value)}/>
                </div>
                <div className={classes.upload_wrap}>
                    <p>Аватар</p>
                    <UploadImage data={user} getFile={getFile} classNameContainer={classes.upload_container} />
                </div>
                <Select name="driver"
                        style={{minWidth: "49%"}}
                        label="Юридическая связь Водителя с Автомобилем"
                        value={statusCar}
                        onChange={e => setStatusCar(e.target.value)}>
                    <option value="OWN">✅ Собственность</option>
                    <option value="POWER_OF_ATTORNEY">📝 Трудовой договор</option>
                    <option value="RENT">🅰️ Аренда</option>
                    <option value="DEPOSIT">⚠️ ЗАЛОГ</option>
                    <option value="ARREST">🛃 АРЕСТ</option>
                    <option value="NO_CONNECTION">🤷🏻‍ НЕТ Юридической СВЯЗИ!</option>
                </Select>
            </Form>}
        </div>
    );
};

const mapStateToProps = state => ({
    users: state.user.users
});

const mapDispatchToProps = (dispatch) => {
    return {
        editCourier: (data) => dispatch(editCourier(data)),
        getError: (payload) => dispatch({type: ERROR, payload: payload}),
        getUsers: () => dispatch(getUsers()),
        uploadAvatar: (formData) => dispatch(uploadAvatar(formData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCourier);
