import {SET_SELECTED_USER, SET_SOCKET_MESSAGE, UPDATE_SOCKET_MESSAGE} from "./actionType";


const initialState = {
    markers: [],
    selectedUser: null,
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_SOCKET_MESSAGE:
            return {
                ...state,
                markers: payload
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: payload
            };
        default:
            return { ...state };
    }
};
