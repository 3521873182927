import React, {useEffect, useState} from "react";
import classes from "./Form.module.scss";
import InputForm from "./input/InputForm";
import Select from "./select/Select";
import Textarea from "./textarea/Textarea"
import Button from "../button/Button";
import {useNavigate} from "react-router-dom";
import ReactStars from "react-rating-stars-component";

const Form = (props) => {
    const {children, data, requestSubmit, path, nameButton, isDeleteButton, requestDelete} = props;

    const navigate = useNavigate();

    const submit = async () => {
        const response = await requestSubmit(data);

        if (response) {
            navigate(path);
        }
    };

    const clear = async () => {
        await requestDelete(data.id);
    };

    const cancel = () => {
        navigate(-1);
    };

    return (
        <>
            <form className={classes.form} onSubmit={e => e.preventDefault()}>
                {children}
            </form>
            <div className={classes.button__container}>
                <div className={classes.button__wrap}>
                    <Button onClick={() => submit()}
                            name={nameButton ? nameButton : 'Сохранить'}
                    />
                </div>
                {!isDeleteButton && <div className={classes.button__wrap}>
                    <Button onClick={() => cancel()}
                            name={'Отменить'}
                    />
                </div>}
                {isDeleteButton ? <div className={classes.button__wrap}>
                    <Button onClick={() => clear()}
                            name={'Очистить'}
                    />
                </div> : null}
            </div>
        </>
    );
};

export default Form;
