import React from 'react';
import classes from "./listRegisterFinishedRoutes.module.scss";
import {formattedDate} from "../../../../utils/helpers/dateHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {connect} from "react-redux";
import {deleteFinishedRoute} from "../../../../store/user/action";
import {addShipmentStatistic} from "../../../../store/shipment-statistics/action";

const ListRegisterFinishedRoutes = (props) => {
    const {deleteFinishedRoute, addShipmentStatistic, element} = props;

    const onDeleteThisRoute = () => {
        addShipmentStatistic({userId: element.id})
        deleteFinishedRoute(element.id);
    }

    return (
        <li className={classes.finished_list}>
                {element.color_marker === "red" && <div className={classes.red_circle}></div>}
                <span>
                    {element.route && element.route.name} -&nbsp;
                    {element.last_name} {element.first_name} {element.patronymic}&nbsp;
                    ВЫГРУЗИЛСЯ {formattedDate(element.route.finishedDate)}
                </span>
            <FontAwesomeIcon icon={faTimes} className={classes.close} onClick={onDeleteThisRoute}/>
        </li>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteFinishedRoute: (data) => dispatch(deleteFinishedRoute(data)),
        addShipmentStatistic: (data) => dispatch(addShipmentStatistic(data))
    }
};

export default connect(null, mapDispatchToProps)(ListRegisterFinishedRoutes);
