import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {getShipmentUser, removeShipmentStatistic} from "../../../store/shipment-statistics/action";
import classes from "./shipment-statistic-user-routes.module.scss";
import dayjs from "dayjs";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { confirmAlert } from 'react-confirm-alert';
import Button from "../../../components/button/Button";

const ShipmentStatisticUserRoutes = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const {shipment_statistic_user, isLoading, getShipmentUser, removeShipmentStatistic} = props;

    useEffect(() => {
        if (params.userId) {
            getShipmentUser(params.userId);
        }
    }, []);

    const onDelete = (id) => {
        confirmAlert({
            title: 'Подтвердите удаление',
            message: 'Вы уверены что хотите сделать это?',
            buttons: [
                {
                    label: 'Да',
                    onClick: () => {
                        removeShipmentStatistic(id);
                    }
                },
                {
                    label: 'Нет'
                }
            ]
        });
    }

    return (
        <>
            <Button
                className={classes["shipment-statistic-user-routes__button"]}
                name="Назад"
                onClick={() => navigate(-1)}
            />
            <table className={classes['shipment-statistic-user-routes__table']}>
                <thead>
                <tr className={classes['shipment-statistic-user-routes__head']}>
                    <td className={classes['shipment-statistic-user-routes__column']}>ID</td>
                    <td className={classes['shipment-statistic-user-routes__column']}>Дата</td>
                    <td className={classes['shipment-statistic-user-routes__column']}></td>
                </tr>
                </thead>
                <tbody>
                {shipment_statistic_user.length ? shipment_statistic_user.map(item =>
                        <tr className={classes['shipment-statistic-user-routes__item']} key={item.id}>
                            <td className={classes['shipment-statistic-user-routes__item--column']}>
                                {item.id}
                            </td>
                            <td className={classes['shipment-statistic-user-routes__item--column']}>{dayjs(item.createdAt).format("DD.MM.YYYY")}</td>
                            <td className={classes['shipment-statistic-user-routes__item--column']}>
                                <FontAwesomeIcon
                                    onClick={e => onDelete(item.id)}
                                    className={classes['shipment-statistic-user-routes__icon']}
                                    icon={faTrash}
                                    size="1x"
                                />
                            </td>
                        </tr>
                    ) :
                    <tr><td></td></tr>
                }
                </tbody>
            </table>
        </>
    );
};

const mapStateToProps = state => ({
    shipment_statistic_user: state.shipment_statistic.shipment_statistic_user,
    isLoading: state.shipment_statistic.isLoading,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getShipmentUser: (params) => dispatch(getShipmentUser(params)),
        removeShipmentStatistic: (id) => dispatch(removeShipmentStatistic(id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStatisticUserRoutes);
