import {
    SET_IS_AUTH,
    SET_CURRENT_USER,
    LOGOUT,
    ERROR,
    GET_USERS,
    IS_LOADING,
    SUCCESS,
    DELETE_USER,
    TOTAL_PAGES,
    TOTAL_USERS,
    PARAMS,
    IS_FINISHED_ROUTES,
    CLEAR_FINISHED_THIS_USER,
    CLEAR_FINISHED_ROUTES_ALL,
    GET_ALL_USERS,
    SORT_FIELD,
    SEARCH_USER, CHANGE_DIRECTION
} from "./actionType";

const initialState = {
    isAuth: false,
    currentUser: {},
    users: [],
    all_users: [],
    error: [],
    success: [],
    isLoading: true,
    totalPages: null,
    totalUsers: null,
    params: {},
    direction: "descending"
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SET_IS_AUTH:
            return {
                ...state,
                isAuth: payload
            }
        case CHANGE_DIRECTION:
            return {
                ...state,
                direction: payload
            }
        case DELETE_USER:
            const users = state.users.filter(user => user.id !== payload)
            return {
                ...state,
                users: users
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                currentUser: payload
            }
        case LOGOUT:
            return {
                ...state,
                currentUser: {},
                isAuth: false
            }
        case ERROR:
            let errors = [...state.error, payload]
            return {
                ...state,
                error: errors
            }
        case SUCCESS:
            let success = [...state.success, payload]
            return {
                ...state,
                success: success
            }
        case GET_USERS:
            return {
                ...state,
                users: payload,
                isLoading: false
            }
        case GET_ALL_USERS:
            return {
                ...state,
                all_users: payload
            }
        case SORT_FIELD:
            return {
                ...state,
                users: payload
            }
        case SEARCH_USER:
            return {
                ...state,
                users: payload
            }
        case IS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case TOTAL_PAGES:
            return {
                ...state,
                totalPages: payload
            }
        case TOTAL_USERS:
            return {
                ...state,
                totalUsers: payload
            }
        case PARAMS:
            return {
                ...state,
                params: payload
            }
        case IS_FINISHED_ROUTES:
            return {
                ...state,
                all_users: state.all_users.map(el => el.id === payload.userId ? {...el, route: payload} : el)
            }
        case CLEAR_FINISHED_THIS_USER:
            return {
                ...state,
                all_users: state.all_users.map(el => el.id === payload.userId ? {...el, route: payload} : el)
            }
        case CLEAR_FINISHED_ROUTES_ALL:
            return {
                ...state,
                all_users: payload
            }
        default:
            return { ...state }
    }
}
