import React, {useState, useLayoutEffect, useEffect} from "react";
import classes from "./Dashboard.module.scss";
import DashboardItem from "./dashboard-item/DashboardItem";
import {DASHBOARD_ITEMS} from "../../../utils/constants/dashboard.items.constants";
import {LOGIN_ROUTE} from "../../../utils/constants/routes.constants";
import {useNavigate} from "react-router-dom";
import {logout} from "../../../store/user/action";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import ListRegister from "../../../views/map/list/list-register/listRegister";
import ListProduct from "../../../views/map/list/list-product/ListProduct";

const Dashboard = (props) => {
    const {logout, products} = props;
    const navigate = useNavigate();
    const [isActiveRegister, setIsActiveRegister] = useState(false);
    const [isActiveProduct, setIsActiveProduct] = useState(false);
    const [isProductLock, setIsProductLock] = useState(false);

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    useEffect(() => {
        if (products.length) {
            setIsProductLock(products[0].isShow);
        }
    }, [products])

    useEffect(() => {
        if (isProductLock) setIsActiveProduct(isProductLock);
    }, [isProductLock])

    const [width] = useWindowSize();

    const onLogout = () => {
        logout();
        navigate(LOGIN_ROUTE);
    };

    const onPressToggleRegister = () => {
        isActiveRegister === false ? setIsActiveRegister(true) : setIsActiveRegister(false);
    }

    const onPressToggleProduct = () => {
        if (!isProductLock) {
            isActiveProduct === false ? setIsActiveProduct(true) : setIsActiveProduct(false);
        }
    }

    const onPressDown = () => {
        setIsActiveRegister(false);
    }

    const close = () => {
        setIsActiveProduct(false);
    }

    return (
        <>
            {width >= 1024 && <ListRegister isActive={isActiveRegister} onPressDown={onPressDown} />}
            {(isActiveProduct && products.length) ? <ListProduct close={close} /> : null}
            <div id="dashboard" className={classes.dashboard}>
                {width < 1024 && <ListRegister isActive={isActiveRegister} onPressDown={onPressDown} />}
                <div className={classes.dashboard_wrap}>
                    {DASHBOARD_ITEMS.map(({id, text, icon, link, type}) =>
                        <DashboardItem key={id} type={type} text={text} products={products} icon={icon} link={link} onPressToggleRegister={onPressToggleRegister} onPressToggleProduct={onPressToggleProduct} activeRegister={isActiveRegister} activeProduct={isProductLock ? true : isActiveProduct} />)}
                    <div className={classes.logout__item}>
                        <div className={classes.logout__link} onClick={() => onLogout()}>
                            <FontAwesomeIcon className={classes.icon} icon={faSignOutAlt} size="2x" />
                            <p className={classes.logout__text}>Выйти</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
    products: state.products.products
});

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
