import {GET_PRODUCTS, CREATE_PRODUCTS, DELETE_PRODUCTS, EDIT_PRODUCTS} from "./actionType";


const initialState = {
    products: [],
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case GET_PRODUCTS:
            return {
                ...state,
                products: payload
            };
        case CREATE_PRODUCTS:
            return {
                ...state,
                products: [payload]
            };
        case EDIT_PRODUCTS:
            return {
                ...state,
                products: [payload]
            };
        case DELETE_PRODUCTS:
            return {
                ...state,
                products: state.products.filter(item => item.id !== +payload)
            };
        default:
            return { ...state };
    }
};
