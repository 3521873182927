import React from 'react';
import classes from "./Search.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

const Search = (props) => {
    const {ActionSearch, placeholder} = props;

    const onChange = (e) => {
        ActionSearch(e.target.value);
    }

    return (
        <div className={classes.search}>
            <input type="search" className={classes.search__input} onChange={onChange} placeholder={placeholder}/>
            <FontAwesomeIcon icon={faSearch} className={classes.icon_search} />
        </div>
    );
};

export default Search;