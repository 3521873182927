import React from "react";
import classes from "./CouriersItem.module.scss";
import {faTrash, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import {COURIERS_ROUTE} from "../../../utils/constants/routes.constants";

const CouriersItem = (props) => {
    const {id, phone, last_name, first_name, onDelete, route} = props;
    const navigate = useNavigate();

    const onClickEdit = () => {
        navigate(`${COURIERS_ROUTE}/${id}`);
    };

    return (
        <tr className={classes.couriers__item}>
            <td className={classes.couriers__column}>{`+${phone}`}</td>
            <td className={classes.couriers__column}>{last_name}</td>
            <td className={classes.couriers__column}>{first_name}</td>
            <td className={classes.couriers__column}>{route && route.name}</td>
            <td className={classes.couriers__column_last}>
                <FontAwesomeIcon
                            onClick={onClickEdit}
                            className={classes.icon}
                            icon={faUserEdit}
                            size="1x"
                />
                <FontAwesomeIcon
                    onClick={e => onDelete(e, id)}
                    className={classes.icon}
                    icon={faTrash}
                    size="1x"
                />
            </td>
        </tr>
    );
};

export default CouriersItem;
