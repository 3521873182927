import React from "react";
import classes from "./Modal.module.scss";

const Modal = (props) => {
    const {error, success} = props;
    return (
        <div className={success ? `${classes.modal} ${classes.success}` : classes.modal}>
            {error && <div>
                <p className={classes.modal__title}>Ошибка</p>
                <p>{error}</p>
            </div>}
            {success && <div>
                <p className={classes.modal__title}>Успешно {success.action}</p>
                <p className={classes.text}>{success.last_name} {success.first_name}</p>
            </div>}
        </div>
    );
};

export default Modal;
