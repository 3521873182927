import MapPageLeaflet from "./views/map/MapPageLeaflet";
import {
    COURIERS_ROUTE,
    LOGIN_ROUTE,
    MAP_ROUTE,
    EDIT_ROUTE,
    ADD_COURIER_ROUTE,
    ADD_PRODUCT, SHIPMENT_STATISTICS
} from "./utils/constants/routes.constants";
import Couriers from "./views/couriers/Couriers";
import Auth from "./views/auth/Auth";
import Edit from "./views/edit/Edit";
import AddCourier from "./views/couriers/add/AddCourier";
import EditCourier from "./views/couriers/edit/EditCourier";
import AddProducts from "./views/products/add/AddProducts";
import ShipmentStatistics from "./views/shipment-statistics/ShipmentStatistics";
import ShipmentStatisticUserRouts
    from "./views/shipment-statistics/shipment-statistic-user-routes/shipment-statistic-user-routes";

export const authRoutes = [
    {
        path: MAP_ROUTE,
        Component: <MapPageLeaflet />
    },
    {
        path: COURIERS_ROUTE,
        Component: <Couriers />
    },
    {
        path: `${COURIERS_ROUTE}/:id`,
        Component: <EditCourier />
    },
    {
        path: ADD_COURIER_ROUTE,
        Component: <AddCourier />
    },
    {
        path: EDIT_ROUTE,
        Component: <Edit />
    },
    {
        path: ADD_PRODUCT,
        Component: <AddProducts />
    },
    {
        path: SHIPMENT_STATISTICS,
        Component: <ShipmentStatistics />
    },
    {
        path: `${SHIPMENT_STATISTICS}/:userId`,
        Component: <ShipmentStatisticUserRouts />
    }
];

export const publicRoutes = [
    {
        path: LOGIN_ROUTE,
        Component: <Auth />
    }
];
