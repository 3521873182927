import React from 'react';
import classes from "./ShipmentStatisticsItem.module.scss";
import {NavLink} from "react-router-dom";
import {SHIPMENT_STATISTICS} from "../../../utils/constants/routes.constants";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ShipmentStatisticsItem = (props) => {
    const {item, onShowCalendar} = props;

    const onShow = (userId) => {
        onShowCalendar(userId)
    }

    return (
        <tr className={classes.shipmentStatisticsItem__item}>
            <td className={classes.shipmentStatisticsItem__column}>
                <NavLink to={`${SHIPMENT_STATISTICS}/${item.shipmentStatistic.userId}`}>
                    {`${item.shipmentStatistic.user.last_name} ${item.shipmentStatistic.user.first_name} ${item.shipmentStatistic.user.patronymic}`}
                </NavLink>
            </td>
            <td className={classes.shipmentStatisticsItem__column}>{item.count}</td>
            <td className={classes.shipmentStatisticsItem__column}>
                <FontAwesomeIcon
                    onClick={e => onShow(item.shipmentStatistic.userId)}
                    className={classes.shipmentStatisticsItem__icon}
                    icon={faPlus}
                    size="1x"
                />
            </td>
        </tr>
    );
};

export default ShipmentStatisticsItem;
