import React, {useEffect, useState} from "react";
import Loader from "react-loader-spinner";
import Dashboard from "../layout/dashboard/Dashboard";
import AppRouter from "./AppRouter";
import {BrowserRouter} from "react-router-dom";
import { connect } from "react-redux";
import {checkAuth} from "../../store/user/action";
import Modal from "../layout/modal/Modal";
import Expire from "../layout/expire/Expire";
import ModalWindow from "../layout/modal-window/ModalWindow";

const Container = (props) => {
    const {isAuth, checkAuth, error, success} = props;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                await checkAuth();
                setLoading(false);
            } catch (e) {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) {
        return (
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#ff7800"
                    height={100}
                    width={100}
                />
            </div>
        );
    }

    return (
        <BrowserRouter>
            <div className="container">
                <ModalWindow />
                {isAuth && <Dashboard />}
                <div className="pages">
                    {error && <div className='modal__wrap'>
                        {error.map((err, i) => {
                            return err && <Expire className="modal__expire" key={i} delay={5000}><Modal error={err} /></Expire>
                        })}
                    </div>}
                    {success && <div className='modal__wrap'>
                        {success.map((item, i) => {
                            return item && <Expire className="modal__expire" key={i} delay={5000}><Modal success={item} /></Expire>
                        })}
                    </div>}
                    <AppRouter />
                </div>
            </div>
        </BrowserRouter>
    );
};

const mapStateToProps = state => ({
    isAuth: state.user.isAuth,
    error: state.user.error,
    success: state.user.success
});

const mapDispatchToProps = {
    checkAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
