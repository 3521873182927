import {MapContainer, TileLayer} from 'react-leaflet'
import React, {useEffect, useState} from "react";
import classes from "./MapPage.module.scss";
import {connect} from "react-redux";
import {addMarker, lockBalloon, removeMarker} from "../../store/map/action"
import {getProducts} from "../../store/products/action"
import Loader from "react-loader-spinner";
import 'leaflet/dist/leaflet.css';
import MapMarker from "./MapMarker";
import {showModal} from "../../store/modal/action";

const MapPageLeaflet = (props) => {
    const {markers, addMarker, removeMarker, lockBalloon, getProducts, selectedUser, showModal} = props;
    const [map, setMap] = useState(null);

    const mapData = {
        center: [55.06062282702159, 82.89465571264769],
        zoom: 4,
    };


    useEffect(() => {
        (async () => {
            await addMarker();
            await getProducts()
        })();
    }, []);

    return (
        <div className={classes.map}>
            <img className={classes.logo} src={require('../../assets/images/logo.png')} alt='logo'/>
            {
                mapData.center.length ?
                    <MapContainer whenCreated={mapInstance => {setMap(mapInstance)}} zoomControl={false} center={mapData.center} zoom={4}
                                  style={{height: '100vh', width: '100wh'}}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {markers && markers.map(marker => {
                            const isNumberCar = marker.balloon.number_car && marker.balloon.number_car.name;
                            return <MapMarker
                                key={marker.userId}
                                map={map}
                                selectedUser={selectedUser}
                                markers={markers}
                                marker={marker}
                                isNumberCar={isNumberCar}
                                addMarker={addMarker}
                                removeMarker={removeMarker}
                                lockBalloon={lockBalloon}
                                showModal={showModal}
                            />
                        })}
                    </MapContainer> :
                    <div className="loader">
                        <Loader
                            type="ThreeDots"
                            color="#ff7800"
                            height={100}
                            width={100}
                        />
                    </div>
            }
        </div>
    );
};

const mapStateToProps = state => ({
    markers: state.map.markers,
    users: state.user.users,
    selectedUser: state.map.selectedUser
});

const mapDispatchToProps = (dispatch) => {
    return {
        getProducts: () => dispatch(getProducts()),
        addMarker: () => dispatch(addMarker()),
        removeMarker: (id) => dispatch(removeMarker(id)),
        lockBalloon: (isShowBalloon, id) => dispatch(lockBalloon(isShowBalloon, id)),
        showModal: (data) => dispatch(showModal(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPageLeaflet);
