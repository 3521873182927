export const Resource = {
    USERS: '/user',
    ALL_USERS: '/user/all',
    UPLOAD_AVATAR: '/user/upload',
    CURRENT_USER: '/user/current',
    AUTH: '/user/auth',
    REGISTRATION: '/user/registration',
    LOCK_BALLOON: '/user/lock-balloon',
    LOGIN: '/user/login',
    USER_EDIT: '/user/edit',
    EMAIL_SEND: '/email_send',
    CLEAR_FINISHED_THIS_USER: '/user/clear-finished-this-user',
    CLEAR_FINISHED_ROUTES_ALL: '/user/clear-finished-routes-all',
    PRODUCTS: '/products',
    PRODUCTS_EDIT_IS_SHOW: '/products/edit-is-show',
    SHIPMENT_STATISTIC: '/shipment-statistics',
    ADD_CUSTOM_SHIPMENT_STATISTIC: '/shipment-statistics/custom',
};
