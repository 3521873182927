import React from 'react';
import classes from './Select.module.scss';

const Select = (props) => {
    const {label, name, value, onChange, style, children} = props;

    return (
        <div className={classes.wrap} style={style}>
            <label htmlFor={name} className={classes.wrap__label}>{label}</label>
            <select className={classes.wrap__select} value={value} onChange={e => onChange(e)}>
                {children}
            </select>
        </div>
    );
};

export default Select;
