import React from 'react';
import classes from './Privacy.module.scss'

const Privacy = () => {
    return (
        <div className={classes.privacy}>
            <div className={classes.page_title}>
                <div className={classes.solid_bg}>
                    <div className={classes.wf_wrap}>
                        <h1>Privacy Policy</h1>
                    </div>
                </div>
            </div>
            <div id={classes.main}>
                <div className={classes.wf_wrap}>
                    <p><span className={classes.title}><strong>PRIVACY NOTICE</strong></span></p>
                    <p className={classes.date}><strong> Last updated May 26, 2019</strong></p>
                    <p> Thank you for choosing to be part of our community
                        at STL (“ company ”, “we”, “us”, or “our”). We are committed to
                        protecting your personal information and your right to privacy. If you have any
                        questions or concerns about our policy, or our practices with regards to your
                        personal information, please contact us at info@animarmedia.com .
                    </p>
                    <p> When you visit our mobile application, and use our
                        services, you trust us with your personal information. We take your privacy very
                        seriously. In this privacy notice, we describe our privacy policy. We seek to
                        explain to you in the clearest way possible what information we collect, how we use
                        it and what rights you have in relation to it. We hope you take some time to read
                        through it carefully, as it is important. If there are any terms in this privacy
                        policy that you do not agree with, please discontinue use of our Apps and our
                        services.</p>
                    <p> This privacy policy applies to all information
                        collected through our mobile application, (« <strong>Apps</strong> «), and/or any
                        related services, sales, marketing or events (we refer to them collectively in this
                        privacy policy as the « <strong>Sites</strong> «).</p>
                    <p><strong>Please read this privacy policy carefully as it
                        will help you make informed decisions about sharing your personal information with
                        us.</strong></p>
                    <p className={classes.title_1}><strong>TABLE OF CONTENTS</strong></p>
                    <p><a href={"#infocollect"}> 1. WHAT INFORMATION DO WE
                        COLLECT?</a></p>
                    <p><a href={"#infouse"}> 2. HOW DO WE USE YOUR
                        INFORMATION?</a></p>
                    <p><a href={"#infoshare"}> 3. WILL YOUR INFORMATION BE SHARED
                        WITH ANYONE?</a></p>
                    <p><a href={"#whoshare"}> 4. WHO WILL YOUR INFORMATION BE
                        SHARED WITH?</a></p>
                    <p><a href={"#cookies"}> 5. DO WE USE COOKIES AND OTHER
                        TRACKING TECHNOLOGIES?</a></p>
                    <p><a href={"#inforetain"}> 6. HOW LONG DO WE KEEP YOUR
                        INFORMATION?</a></p>
                    <p><a href={"#infosafe"}> 7. HOW DO WE KEEP YOUR INFORMATION
                        SAFE?</a></p>
                    <p><a href={"#infominors"}> 8. DO WE COLLECT INFORMATION FROM
                        MINORS?</a></p>
                    <p><a href={"#privacyrights"}> 9. WHAT ARE YOUR PRIVACY
                        RIGHTS?</a></p>
                    <p><a href={"#DNT"}> 10. CONTROLS FOR DO-NOT-TRACK
                        FEATURES</a></p>
                    <p><a href={"#caresidents"}> 11. DO CALIFORNIA RESIDENTS HAVE
                        SPECIFIC PRIVACY RIGHTS?</a></p>
                    <p><a href={"#policyupdates"}> 12. DO WE MAKE UPDATES TO THIS
                        POLICY?</a></p>
                    <p><a href={"#contact"}> 13. HOW CAN YOU CONTACT US ABOUT
                        THIS POLICY?</a></p>
                    <p id="infocollect" className={classes.title_1}><strong>1. WHAT
                        INFORMATION DO WE COLLECT?</strong></p>
                    <p className={classes.black}><strong>Information automatically collected</strong></p>
                    <p><strong><em>In Short:</em></strong><em>Some information
                        – such as IP address and/or browser and device characteristics – is collected
                        automatically when you visit our Apps .</em></p>
                    <p> We automatically collect certain information when you
                        visit, use or navigate the Apps . This information does not reveal your specific
                        identity (like your name or contact information) but may include device and usage
                        information, such as your IP address, browser and device characteristics, operating
                        system, language preferences, referring URLs, device name, country, location,
                        information about how and when you use our Apps and other technical information.
                        This information is primarily needed to maintain the security and operation of our
                        Apps , and for our internal analytics and reporting purposes. </p>
                    <p> Like many businesses, we also collect information
                        through cookies and similar technologies. </p>
                    <p className={classes.black}><strong>Information collected through our Apps</strong></p>
                    <p><strong><em>In Short:</em></strong><em> We may collect information regarding your
                        mobile device, push notifications, when you use our apps.</em></p>
                    <p> If you use our Apps, we may also collect the following information: </p>
                    <li><em>Mobile Device Data.</em> We may automatically collect device information (such
                        as your mobile device ID, model and manufacturer), operating system, version
                        information and IP address.
                    </li>
                    <li><em>Push Notifications.</em> We may request to send you push notifications regarding
                        your account or the mobile application. If you wish to opt-out from receiving these
                        types of communications, you may turn them off in your device's settings.
                    </li>
                    <p><strong>Information collected from other sources</strong></p>
                    <p><strong><em>In Short:</em></strong><em> We may collect limited data from public
                        databases, marketing partners, and other outside sources.</em></p>
                    <p> We may obtain information about you from other sources, such as public databases,
                        joint marketing partners, as well as from other third parties. Examples of the
                        information we receive from other sources include: social media profile information
                        ; marketing leads and search results and links, including paid listings (such as
                        sponsored links). </p>
                    <p id="infouse" className={classes.title_1}><strong>2. HOW DO WE USE YOUR
                        INFORMATION?</strong></p>
                    <p><strong><em>In Short:</em></strong><em>We process your information for purposes based
                        on legitimate business interests, the fulfillment of our contract with you,
                        compliance with our legal obligations, and/or your consent.</em></p>
                    <p> We use personal information collected via our Apps for a variety of business
                        purposes described below. We process your personal information for these purposes in
                        reliance on our legitimate business interests («Business Purposes»), in order to
                        enter into or perform a contract with you («Contractual»), with your consent
                        («Consent»), and/or for compliance with our legal obligations («Legal Reasons»). We
                        indicate the specific processing grounds we rely on next to each purpose listed
                        below.</p>
                    <p> We use the information we collect or receive: </p>
                    <li><strong>To send you marketing and promotional communications.</strong> We and/or our
                        third party marketing partners may use the personal information you send to us for
                        our marketing purposes, if this is in accordance with your marketing preferences.
                        You can opt-out of our marketing emails at any time (see the « <a
                            href={"#privacyrights"}> WHAT ARE YOUR PRIVACY RIGHTS</a> » below).
                    </li>
                    <li><strong>For other Business Purposes.</strong> We may use your information for other
                        Business Purposes, such as data analysis, identifying usage trends, determining the
                        effectiveness of our promotional campaigns and to uate and improve our Apps ,
                        products, services, marketing and your experience.
                    </li>
                    <p id="infoshare" className={classes.title_1}><strong>3. WILL YOUR
                        INFORMATION BE SHARED WITH ANYONE?</strong></p>
                    <p><strong><em>In Short:</em></strong><em>We only share information with your consent,
                        to comply with laws, to protect your rights, or to fulfill business
                        obligations.</em></p>
                    <div>
                        We may process or share data based on the following legal basis:
                    </div>
                    <ul>
                        <li><strong>Consent:</strong> We may process your data if you have given us specific
                            consent to use your personal information in a specific purpose.
                        </li>
                        <li><strong>Legitimate Interests:</strong> We may process your data when it is
                            reasonably necessary to achieve our legitimate business interests.
                        </li>
                        <li><strong>Performance of a Contract:</strong> Where we have entered into a
                            contract with you, we may process your personal information to fulfill the terms
                            of our contract.
                        </li>
                        <li><strong>Legal Obligations:</strong> We may disclose your information where we
                            are legally required to do so in order to comply with applicable law,
                            governmental requests, a judicial proceeding, court order, or legal process,
                            such as in response to a court order or a subpoena (including in response to
                            public authorities to meet national security or law enforcement requirements).
                        </li>
                        <li><strong>Vital Interests:</strong> We may disclose your information where we
                            believe it is necessary to investigate, prevent, or take action regarding
                            potential violations of our policies, suspected fraud, situations involving
                            potential threats to the safety of any person and illegal activities, or as
                            evidence in litigation in which we are involved.
                        </li>
                    </ul>
                    <p> More specifically, we may need to process your data or share your personal
                        information in the following situations: </p>
                    <ul>
                        <li><strong>Vendors, Consultants and Other Third-Party Service
                            Providers.</strong> We may share your data with third party vendors, service
                            providers, contractors or agents who perform services for us or on our behalf
                            and require access to such information to do that work. Examples include:
                            payment processing, data analysis, email delivery, hosting services, customer
                            service and marketing efforts. We may allow selected third parties to use
                            tracking technology on the Apps , which will enable them to collect data about
                            how you interact with the Apps over time. This information may be used to, among
                            other things, analyze and track data, determine the popularity of certain
                            content and better understand online activity. Unless described in this Policy,
                            we do not share, sell, rent or trade any of your information with third parties
                            for their promotional purposes.
                        </li>
                        <li><strong>Business Transfers.</strong> We may share or transfer your information
                            in connection with, or during negotiations of, any merger, sale of company
                            assets, financing, or acquisition of all or a portion of our business to another
                            company.
                        </li>
                        <li><strong>Offer Wall.</strong> Our Apps may display a third-party hosted “offer
                            wall.” Such an offer wall allows third-party advertisers to offer virtual
                            currency, gifts, or other items to users in return for acceptance and completion
                            of an advertisement offer. Such an offer wall may appear in our mobile
                            application and be displayed to you based on certain data, such as your
                            geographic area or demographic information. When you click on an offer wall, you
                            will leave our mobile application. A unique identifier, such as your user ID,
                            will be shared with the offer wall provider in order to prevent fraud and
                            properly credit your account.
                        </li>
                    </ul>
                    <div>
                        <p id="whoshare" className={classes.title_1}><strong>4. WHO WILL YOUR
                            INFORMATION BE SHARED WITH?</strong></p>
                    </div>
                    <div>
                        <strong><em>In Short:</em></strong><em>We only share information with the following
                        third parties.</em>
                    </div>
                    <div>
                        We only share and disclose your information with the following third parties. We
                        have categorized each party so that you may be easily understand the purpose of our
                        data collection and processing practices. If we have processed your data based on
                        your consent and you wish to revoke your consent, please contact us.
                    </div>
                    <div>
                        <ul>
                            <li><strong>Web and Mobile
                                Analytics</strong>
                                Yandex Metrica
                            </li>
                        </ul>
                        <div>
                            <div id="cookies"
                                 className={classes.title_1}>
                                <strong>5. DO WE USE
                                    COOKIES AND OTHER
                                    TRACKING
                                    TECHNOLOGIES?</strong>
                            </div>
                            <p><strong><em>In
                                Short:</em></strong><em>We
                                may use cookies and
                                other tracking
                                technologies to collect
                                and store your
                                information.</em></p>
                            <p> We may use cookies and
                                similar tracking
                                technologies (like web
                                beacons and pixels) to
                                access or store
                                information. Specific
                                information about how we
                                use such technologies
                                and how you can refuse
                                certain cookies is set
                                out in our Cookie Policy
                                .</p>
                            <p id="inforetain"
                               className={classes.title_1}>
                                <strong>6. HOW LONG DO
                                    WE KEEP YOUR
                                    INFORMATION?</strong>
                            </p>
                            <p><strong><em>In
                                Short:</em></strong><em>We
                                keep your information
                                for as long as necessary
                                to fulfill the purposes
                                outlined in this privacy
                                policy unless otherwise
                                required by law.</em>
                            </p>
                            <p> We will only keep your
                                personal information for
                                as long as it is
                                necessary for the
                                purposes set out in this
                                privacy policy, unless a
                                longer retention period
                                is required or permitted
                                by law (such as tax,
                                accounting or other
                                legal requirements). No
                                purpose in this policy
                                will require us keeping
                                your personal
                                information for longer
                                than 2
                                years .
                            </p>
                            <p>When we have no ongoing
                                legitimate business need
                                to process your personal
                                information, we will
                                either delete or
                                anonymize it, or, if
                                this is not possible
                                (for example, because
                                your personal
                                information has been
                                stored in backup
                                archives), then we will
                                securely store your
                                personal information and
                                isolate it from any
                                further processing until
                                deletion is
                                possible.</p>
                            <p id="infosafe"
                               className={classes.title_1}>
                                <strong>7. HOW DO WE
                                    KEEP YOUR
                                    INFORMATION
                                    SAFE?</strong></p>
                            <p><strong><em>In
                                Short:</em></strong><em>We
                                aim to protect your
                                personal information
                                through a system of
                                organisational and
                                technical security
                                measures.</em></p>
                            <p> We have implemented
                                appropriate technical
                                and organisational
                                security measures
                                designed to protect the
                                security of any personal
                                information we process.
                                However, please also
                                remember that we cannot
                                guarantee that the
                                internet itself is 100%
                                secure. Although we will
                                do our best to protect
                                your personal
                                information,
                                transmission of personal
                                information to and from
                                our Apps is at your own
                                risk. You should only
                                access the services
                                within a secure
                                environment. </p>
                            <p id="infominors"
                               className={classes.title_1}>
                                <strong>8. DO WE COLLECT
                                    INFORMATION FROM
                                    MINORS?</strong></p>
                            <p><strong><em>In
                                Short:</em></strong><em>We
                                do not knowingly collect
                                data from or market to
                                children under 13 years
                                of age.</em></p>
                            <p> We do not knowingly
                                solicit data from or
                                market to children under
                                13 years of age. By
                                using the Apps , you
                                represent that you are
                                at least 13 or that you
                                are the parent or
                                guardian of such a minor
                                and consent to such
                                minor dependent's use of
                                the Apps . If we learn
                                that personal
                                information from users
                                less than 18 years of
                                age has been collected,
                                we will deactivate the
                                account and take
                                reasonable measures to
                                promptly delete such
                                data from our records.
                                If you become aware of
                                any data we have
                                collected from children
                                under age 13, please
                                contact us
                                at info@animarmedia.com .
                            </p>
                            <p id="privacyrights"
                               className={classes.title_1}>
                                <strong>9. WHAT ARE YOUR
                                    PRIVACY
                                    RIGHTS?</strong></p>
                            <p><strong><em>In
                                Short:</em></strong><em>In
                                some regions, such as
                                the European Economic
                                Area, you have rights
                                that allow you greater
                                access to and control
                                over your personal
                                information. You may
                                review, change, or
                                terminate your account
                                at any time.</em></p>
                            <p>In some regions (like the
                                European Economic Area),
                                you have certain rights
                                under applicable data
                                protection laws. These
                                may include the right
                                (i) to request access
                                and obtain a copy of
                                your personal
                                information, (ii) to
                                request rectification or
                                erasure; (iii) to
                                restrict the processing
                                of your personal
                                information; and (iv) if
                                applicable, to data
                                portability. In certain
                                circumstances, you may
                                also have the right to
                                object to the processing
                                of your personal
                                information. To make
                                such a request, please
                                use the <a
                                    href={"#contact"}> contact
                                    details</a> provided
                                below. We will consider
                                and act upon any request
                                in accordance with
                                applicable data
                                protection laws.</p>
                            <p>If we are relying on your
                                consent to process your
                                personal information,
                                you have the right to
                                withdraw your consent at
                                any time. Please note
                                however that this will
                                not affect the
                                lawfulness of the
                                processing before its
                                withdrawal.</p>
                            <p>If you are resident in
                                the European Economic
                                Area and you believe we
                                are unlawfully
                                processing your personal
                                information, you also
                                have the right to
                                complain to your local
                                data protection
                                supervisory authority.
                                You can find their
                                contact details here: <a
                                    href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                    target="_blank"> http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>
                            </p>
                            <p><strong><u>Cookies and
                                similar
                                technologies:</u></strong> Most
                                Web browsers are set to
                                accept cookies by
                                default. If you prefer,
                                you can usually choose
                                to set your browser to
                                remove cookies and to
                                reject cookies. If you
                                choose to remove cookies
                                or reject cookies, this
                                could affect certain
                                features or services of
                                our Apps . To opt-out of
                                interest-based
                                advertising by
                                advertisers on our Apps
                                visit <a
                                    href="http://www.aboutads.info/choices/"
                                    target="_blank"> http://www.aboutads.info/choices/</a> .
                            </p>
                            <p id="DNT"
                               className={classes.title_1}>
                                <strong>10. CONTROLS FOR
                                    DO-NOT-TRACK
                                    FEATURES</strong>
                            </p>
                            <p>Most web browsers and
                                some mobile operating
                                systems and mobile
                                applications include a
                                Do-Not-Track (“DNT”)
                                feature or setting you
                                can activate to signal
                                your privacy preference
                                not to have data about
                                your online browsing
                                activities monitored and
                                collected. No uniform
                                technology standard for
                                recognizing and
                                implementing DNT signals
                                has been finalized. As
                                such, we do not
                                currently respond to DNT
                                browser signals or any
                                other mechanism that
                                automatically
                                communicates your choice
                                not to be tracked
                                online. If a standard
                                for online tracking is
                                adopted that we must
                                follow in the future, we
                                will inform you about
                                that practice in a
                                revised version of this
                                Privacy Policy.</p>
                            <p id="caresidents"
                               className={classes.title_1}>
                                <strong>11. DO
                                    CALIFORNIA RESIDENTS
                                    HAVE SPECIFIC
                                    PRIVACY
                                    RIGHTS?</strong></p>
                            <p><strong><em>In
                                Short:</em></strong><em>Yes,
                                if you are a resident of
                                California, you are
                                granted specific rights
                                regarding access to your
                                personal
                                information.</em></p>
                            <p>California Civil Code
                                Section 1798.83, also
                                known as the “Shine The
                                Light” law, permits our
                                users who are California
                                residents to request and
                                obtain from us, once a
                                year and free of charge,
                                information about
                                categories of personal
                                information (if any) we
                                disclosed to third
                                parties for direct
                                marketing purposes and
                                the names and addresses
                                of all third parties
                                with which we shared
                                personal information in
                                the immediately
                                preceding calendar year.
                                If you are a California
                                resident and would like
                                to make such a request,
                                please submit your
                                request in writing to us
                                using the contact
                                information provided
                                below.</p>
                            <p> If you are under 18
                                years of age, reside in
                                California, and have a
                                registered account with
                                the Apps , you have the
                                right to request removal
                                of unwanted data that
                                you publicly post on the
                                Apps . To request
                                removal of such data,
                                please contact us using
                                the contact information
                                provided below, and
                                include the email
                                address associated with
                                your account and a
                                statement that you
                                reside in California. We
                                will make sure the data
                                is not publicly
                                displayed on the Apps ,
                                but please be aware that
                                the data may not be
                                completely or
                                comprehensively removed
                                from our systems.</p>
                            <p id="policyupdates"
                               className={classes.title_1}>
                                <strong>12. DO WE MAKE
                                    UPDATES TO THIS
                                    POLICY?</strong></p>
                            <p><strong><em>In
                                Short:</em></strong><em>Yes,
                                we will update this
                                policy as necessary to
                                stay compliant with
                                relevant laws.</em></p>
                            <p>We may update this
                                privacy policy from time
                                to time. The updated
                                version will be
                                indicated by an updated
                                “Revised” date and the
                                updated version will be
                                effective as soon as it
                                is accessible. If we
                                make material changes to
                                this privacy policy, we
                                may notify you either by
                                prominently posting a
                                notice of such changes
                                or by directly sending
                                you a notification. We
                                encourage you to review
                                this privacy policy
                                frequently to be
                                informed of how we are
                                protecting your
                                information.</p>
                            <p id="contact"
                               className={classes.title_1}>
                                <strong>13. HOW CAN YOU
                                    CONTACT US ABOUT
                                    THIS
                                    POLICY?</strong></p>
                            <p> If you have questions or
                                comments about this
                                policy, you may email us
                                at info@animarmedia.com
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default Privacy;
