import {SHOW_MODAL, HIDE_MODAL} from "./actionType";

const initialState = {
    modal: null
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case SHOW_MODAL:
            return {
                ...state,
                modal: payload
            }
        case HIDE_MODAL:
            return {
                ...state,
                modal: null
            }
        default:
            return { ...state }
    }
}
