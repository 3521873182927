import React, {useState} from "react";
import classes from "./Auth.module.scss";
import Button from "../../components/button/Button";
import {loginUser} from "../../store/user/action";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import {MAP_ROUTE} from "../../utils/constants/routes.constants";
import InputForm from "../../components/form/input/InputForm";

const Auth = (props) => {
    const {loginUser} = props;
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onClick = async () => {
        await loginUser(email, password);
        navigate(MAP_ROUTE);
    };

    return (
        <div className={classes.auth}>
            <form className={classes.form} onSubmit={e => e.preventDefault()}>
                <h2 className={classes.title}>Авторизация</h2>
                <InputForm name="email"
                           type="email"
                           placeholder="Введите email"
                           label="Логин"
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                />
                <InputForm name="password"
                           type="password"
                           placeholder="Введите пароль"
                           label="Пароль"
                           value={password}
                           onChange={e => setPassword(e.target.value)}
                />
                <div className={classes.button__container}>
                    <Button onClick={() => onClick()} name={'Войти'} style={{width: "100%"}}/>
                </div>
            </form>
        </div>
    );
};

const mapDispatchToProps = {
    loginUser
};

export default connect(null, mapDispatchToProps)(Auth);
