import React from "react";
import classes from "./InputForm.module.scss";
import Input from "react-phone-number-input/input";

const InputForm = (props) => {
    const {
        label,
        name,
        isPhone,
        country,
        placeholder,
        value,
        onChange,
        type,
        error,
        style
    } = props;
    return (
        <div className={classes.wrap} style={style}>
            {label && <label className={classes.wrap__label} htmlFor={name}>{label}</label>}
            {isPhone ?
                <Input
                    country={country}
                    international
                    withCountryCallingCode
                    className={classes.wrap__input}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => onChange(e)}
                /> :
                <input className={classes.wrap__input}
                       name={name}
                       id={name}
                       type={type}
                       placeholder={placeholder}
                       value={value}
                       onChange={e => onChange(e)}
                />
            }
            {error && <span className={classes.error}>{error}</span>}
        </div>
    );
};

export default InputForm;
