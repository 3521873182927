import React from "react";
import classes from "./listRegisterItem.module.scss";

const ListRegisterItem = (props) => {
    const {element, handleClickRoute} = props;

  const styles = {
        color: element.lastTime && element.lastTime <= 120 ? 'green' : 'red',
        fontWeight: element.lastTime && element.lastTime <= 120 ? 400 : 600
    }

    return (
        <li className={classes.list}>
            {element.balloon.color_marker === "red" && <div className={classes.red_circle}></div>}
            <span onClick={() => handleClickRoute(element.userId)} className={classes.list__route}>{element.balloon.route && element.balloon.route.name} </span>
            <span>
            -&nbsp;{element.balloon.last_name} {element.balloon.first_name} {element.balloon.patronymic}
                {(element.balloon.dossier && element.balloon.dossier.url) &&
                    <a href={element.balloon.dossier.url} target={'_blank'} className={'dossier'}>&nbsp;- досье</a>
                }&nbsp;
                {element.lastTime && <span style={styles}>
                {element.lastTime}&nbsp;мин.{element.lastTime && element.lastTime <= 120 &&
                    <span style={styles}>(онлайн)</span>}
            </span>}
        </span></li>
    )
}

export default ListRegisterItem;
