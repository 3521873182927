import React, {useState} from 'react';
import classes from "./Support.module.scss";
import InputForm from "../../components/form/input/InputForm";
import Button from "../../components/button/Button";
import {Api} from "../../services/api";
import {Resource} from "../../services/ApiResources";

const Support = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [success, setSuccess] = useState(null);
    const onClick = async () => {
        try {
            const res = await Api.POST(Resource.EMAIL_SEND, {email, name, text})
            if (res) {
                setEmail('');
                setName('');
                setText('');
                setSuccess({text: 'Сообщение отправлено', isSuccess: true});
            }
        } catch (err) {
            if (err.response.data.message) {
                setSuccess({text: err.response.data.message, isSuccess: false})
            }
        }
    };
    return (
        <div className={classes.support}>
            <form className={classes.support_form} onSubmit={e => e.preventDefault()}>
                <h2 className={classes.support_title}>Возникла проблема или вопрос - напиши нам.</h2>
                <InputForm name="name"
                           type="text"
                           placeholder="Введите имя"
                           label="Имя"
                           value={name}
                           onChange={e => setName(e.target.value)}
                />
                <InputForm name="email"
                           type="email"
                           placeholder="Введите email"
                           label="Email"
                           value={email}
                           onChange={e => setEmail(e.target.value)}
                />
                <InputForm name="text"
                           type="text"
                           placeholder="Введите текст"
                           label="Текст"
                           value={text}
                           isTextarea={true}
                           onChange={e => setText(e.target.value)}
                />
                <div className={classes.support_button__container}>
                    {success && <p className={success.isSuccess ? classes.support_success : classes.support_err}>{success.text}</p>}
                    <Button onClick={() => onClick()} name={'Отправить'} style={{width: "100%"}}/>
                </div>
            </form>
        </div>
    );
}

export default Support;
