import {faCar, faChartBar, faListUl, faMap, faRubleSign, faUser} from "@fortawesome/free-solid-svg-icons";
import {MAP_ROUTE, COURIERS_ROUTE, EDIT_ROUTE, SHIPMENT_STATISTICS} from "./routes.constants";

export const DASHBOARD_ITEMS = [
    {
        id: 1,
        text: "Карта",
        icon: faMap,
        link: MAP_ROUTE,
        type: "nav-link"
    },
    {
        id: 2,
        text: "Список",
        icon: faListUl,
        link: null,
        type: "list-register"
    },
    {
        id: 3,
        text: 'Курьеры',
        icon: faCar,
        link: COURIERS_ROUTE,
        type: "nav-link"
    },
    {
        id: 4,
        text: 'Статистика',
        icon: faChartBar,
        link: SHIPMENT_STATISTICS,
        type: "nav-link"
    },
    {
        id: 5,
        text: 'Профиль',
        icon: faUser,
        link: EDIT_ROUTE,
        type: "nav-link"
    },
    {
        id: 6,
        text: 'Сумма',
        icon: faRubleSign,
        link: null,
        type: "list-product"
    }
];
