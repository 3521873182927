import {combineReducers} from "redux";
import userReducer from "./user/userReducer";
import mapReducer from "./map/mapReducer";
import productsReducer from "./products/productsReducer";
import modalReducer from "./modal/modalReducer";
import shipmentStatistics from "./shipment-statistics/shipmentStatisticsReducer";

export const RootReducer = combineReducers({
    user: userReducer,
    map: mapReducer,
    products: productsReducer,
    modal: modalReducer,
    shipment_statistic: shipmentStatistics,
});
