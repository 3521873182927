import {Api} from "../../services/api";
import {Resource} from "../../services/ApiResources";
import {GET_PRODUCTS, CREATE_PRODUCTS, DELETE_PRODUCTS, EDIT_PRODUCTS} from "./actionType";
import {socket} from "../../services/connectedSocket";

export const getProducts = () => {
    return async (dispatch) => {
        const response = await Api.GET({url: Resource.PRODUCTS});
        dispatch({type: GET_PRODUCTS, payload: response});
        await socket.on('products:is_update_show', async (data) => {
            if (data.isUpdateShow) {
                const response = await Api.GET({url: Resource.PRODUCTS});
                dispatch({type: GET_PRODUCTS, payload: response});
            }
        });
    };
}

export const createProduct = (data) => {
    return async (dispatch) => {
        const response = await Api.POST(Resource.PRODUCTS, data);
        dispatch({type: CREATE_PRODUCTS, payload: response});
        return response;
    };
}

export const editProduct = (data) => {
    return async (dispatch) => {
        const response = await Api.PUT(Resource.PRODUCTS, data);
        dispatch({type: EDIT_PRODUCTS, payload: response});
        return response;
    };
}

export const editIsShowProduct = (data) => {
    return async (dispatch) => {
        const response = await Api.PUT(Resource.PRODUCTS_EDIT_IS_SHOW, data);
        dispatch({type: EDIT_PRODUCTS, payload: response});
        await socket.emit("products:is_update_show", {isUpdateShow: true})
        return response;
    };
}

export const deleteProduct = (id) => {
    return async (dispatch) => {
        const response = await Api.DELETE(Resource.PRODUCTS, id);
        dispatch({type: DELETE_PRODUCTS, payload: response});
        return response;
    };
}

