import React, {useState, useEffect} from 'react';
import classes from './UploadImage.module.scss';
import Input from '../../form/input/InputForm';
import Button from '../../button/Button';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileImage} from "@fortawesome/free-solid-svg-icons";
import {DOMAIN} from "../../../utils/constants/domain.constans";

function dataURLtoFile(dataurl, filename) {

    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type:mime});
}

const UploadImage = (props) => {
    const {data, classNameContainer, getFile} = props;

    const [image, setImage] = useState(null);
    const [cropData, setCropData] = useState(null);
    const [cropper, setCropper] = useState();

    const [isShow, setIsShow] = useState(false);
    const [file, setFile] = useState("");

    useEffect(() => {
        if (data) {
            setFile(data.image ? {name: data.image} : "");
        }
    }, [data]);

    useEffect(() => {
        if (cropData) {
            const cropDataFile = dataURLtoFile(cropData, new Date());
            getFile(cropDataFile);
        }
    }, [cropData])

    const onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setIsShow(true);
        };
        reader.readAsDataURL(files[0]);
    };

    const getCropData = async () => {
        setIsShow(false);
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas().toDataURL());
        }
    };

    const cancelCropData = () => {
        setIsShow(false);
    };

    return (
        <div className={classNameContainer ? `${classNameContainer} ${classes['upload-image']}` : classes['upload-image']}>
            {isShow ? <div className={classes.cropper}>
                <Cropper
                    style={{ height: "90%", width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                    guides={true}
                />
                <div className={classes.button_wrap}>
                    <Button onClick={cancelCropData} style={{maxWidth: 200}} name={'Отменить'} />
                    <Button onClick={getCropData} style={{maxWidth: 200}} name={'Сохранить'} />
                </div>
            </div> : null}
            <div className={classes["upload-image__upload-box"]}>
                {file ?
                    <div className={classes["upload-image__wrap-img"]}>
                        {!cropData ?
                            <img src={`${DOMAIN}/public/avatars/${file.name}`} alt={file.name}/> :
                            <img src={`${cropData}`} alt={''} />
                        }
                        <Input type={"file"} name={"upload"} onChange={onChange}/>
                    </div> :
                    <div className={classes["upload-image__wrap-img"]}>
                        {!cropData ?
                            <FontAwesomeIcon icon={faFileImage} color={"#a8b2b5"} size="5x"/> :
                            <img src={`${cropData}`} alt={''} />
                        }
                        <Input type={"file"} name={"upload"} onChange={onChange}/>
                    </div>}
            </div>
        </div>
    );
};

export default UploadImage;