import React from "react";
import classes from "./DashboardItem.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";

const DashboardItem = (props) => {
    const {text, icon, link, type, onPressToggleRegister, activeRegister, activeProduct, onPressToggleProduct, products} = props;

    return (
        <div className={classes.dashboard__item}>
            {type === "nav-link" && <NavLink className={
                ({isActive}) => isActive ?
                    `${classes.active} ${classes.dashboard__link}` :
                    classes.dashboard__link} to={link
            }
            >
                {icon && <FontAwesomeIcon className={classes.icon} icon={icon} size="2x" />}
                {text && <p className={classes.dashboard__text}>{text}</p>}
            </NavLink>}
            {type === "list-register" && <div className={activeRegister ? `${classes.active} ${classes.dashboard__link}` :
                classes.dashboard__link} onClick={onPressToggleRegister}>
                {icon && <FontAwesomeIcon className={classes.icon} icon={icon} size="2x" />}
                {text && <p className={classes.dashboard__text}>{text}</p>}
            </div>}
            {type === "list-product" && <div className={(activeProduct && products.length) ? `${classes.active} ${classes.dashboard__link}` :
                classes.dashboard__link} onClick={products.length ? onPressToggleProduct : null}>
                {icon && <FontAwesomeIcon className={classes.icon} icon={icon} size="2x" />}
                {text && <p className={classes.dashboard__text}>{text}</p>}
            </div>}
        </div>
    );
};

export default DashboardItem;
