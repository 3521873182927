import React, {useEffect, useMemo, useState} from "react";
import classes from "./listRegister.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import ListRegisterItem from "../list-register-item/listRegisterItem";
import ListRegisterFinishedRoutes from "../list-register-finished-routes/listRegisterFinishedRoutes";
import {connect} from "react-redux";
import {deleteFinishedRoutesAll, getUsers, isFinishedRoute} from "../../../../store/user/action";
import {selectUser} from "../../../../store/map/action";
import {addShipmentStatistic} from "../../../../store/shipment-statistics/action";

const ListRegister = (props) => {
    const {
        markers,
        isActive,
        onPressDown,
        getUsers,
        isFinishedRoute,
        users,
        selectUser,
        all_users,
        deleteFinishedRoutesAll,
        addShipmentStatistic} = props;
    const [showFinishedComponent, setShowFinishedComponent] = useState(false);
    const [finishedComponent, setFinishedComponent] = useState([]);
    const [markersComponent, setMarkersComponent] = useState([]);

    markersComponent.sort((a, b) => {
        return parseInt(a.balloon.route.name) - parseInt(b.balloon.route.name)
    });

    finishedComponent.sort((a, b) => {
        return parseInt(a.route.name) - parseInt(b.route.name)
    });

    useEffect(() => {
        isFinishedRoute();
    }, [])

    useEffect(() => {
        getUsers();
    }, [])

    useEffect(() => {
        const markersIsFinished = markers.filter(el => !el.balloon.route.isFinished);
        setMarkersComponent(markersIsFinished);
    }, [markers])

    useEffect(() => {
        const isFinishedRoute = all_users.filter(el => el.route.isFinished);
        setFinishedComponent(isFinishedRoute);
        isFinishedRoute.length ? setShowFinishedComponent(true) : setShowFinishedComponent(false);
    }, [all_users])

    const onDeleteRoutesAll = () => {
        Promise.all(finishedComponent.map(async el => {
            await addShipmentStatistic({userId: el.id})
        }))
        deleteFinishedRoutesAll();
    }

    const onClickRoute = (id) => {
        selectUser(id);
    }

    const redMarkerRegisterCount = useMemo(() => markersComponent.filter(element => element.balloon.color_marker === 'red').length, [markersComponent])
    const redMarkerFinishedCount = useMemo(() => finishedComponent.filter(element => element.color_marker === 'red').length, [finishedComponent])

    return (
        <div className={classes.list_register}>
            {isActive &&
                <div>
                    <div className={classes.list_register_wrap}>
                        <ol className={classes.list}>
                            {markersComponent.map(el =>
                                (el.balloon.route && !el.balloon.route.isFinished)  && <ListRegisterItem handleClickRoute={onClickRoute} element={el} key={el.userId}/>
                            )}
                        </ol>
                        {markersComponent.length > 0 ?
                            <div className={classes.result}>Итого: <span
                                className={classes.result_text}>{markersComponent.length} рейсов в ПУТИ</span></div> :
                            <div><span className={classes.result_text}>Нет курьеров в пути</span></div>
                        }
                        <div className={classes.caviar_wrapper}>
                            <div className={classes.red_circle}></div>
                            <span className={classes.caviar_text}>В том числе ИКОРНЫХ: {redMarkerRegisterCount}</span>
                        </div>
                        {!showFinishedComponent && <FontAwesomeIcon icon={faAngleDown} className={classes.icon_down} onClick={onPressDown}/>}
                    </div>
                    {showFinishedComponent && <div className={classes.list_register_wrap}>
                        <ol className={classes.list}>
                            {finishedComponent.map(el =>
                                el && <ListRegisterFinishedRoutes element={el} key={el.id}/>
                            )}
                        </ol>
                        {finishedComponent.length > 0 &&
                            <div className={classes.result}>Итого: <span
                                className={classes.result_text}>{finishedComponent.length} рейс ВЫГРУЖЕН</span></div>
                        }
                        <div className={classes.caviar_wrapper}>
                            <div className={classes.red_circle}></div>
                            <span className={classes.caviar_text}>В том числе ИКОРНЫХ: {redMarkerFinishedCount}</span>
                        </div>
                        <span onClick={onDeleteRoutesAll} className={classes.clearFinished}>очистить всё</span>
                        <FontAwesomeIcon icon={faAngleDown} className={classes.icon_down} onClick={onPressDown}/>
                    </div>}
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => ({
    markers: state.map.markers,
    users: state.user.users,
    all_users: state.user.all_users,
});

const mapDispatchToProps = (dispatch) => {
    return {
        deleteFinishedRoutesAll: () => dispatch(deleteFinishedRoutesAll()),
        getUsers: () => dispatch(getUsers()),
        isFinishedRoute: () => dispatch(isFinishedRoute()),
        selectUser: (id) => dispatch(selectUser(id)),
        addShipmentStatistic: (data) => dispatch(addShipmentStatistic(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListRegister);
