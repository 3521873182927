import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getUsers, paginationParamsUsers, SearchUser, sortField} from "../../store/user/action";
import Button from "../../components/button/Button";
import CouriersItem from "./couriers-item/CouriersItem";
import classes from "./Couriers.module.scss";
import {useNavigate} from "react-router-dom";
import {ADD_COURIER_ROUTE, ADD_PRODUCT} from "../../utils/constants/routes.constants";
import {deleteUser} from "../../store/user/action";
import Loader from "react-loader-spinner";
import Pagination from "../../components/pagination/Pagination";
import Search from "../../components/layout/search/Search";

const statisticField = [
    {id: 1, sortKey: "last_name", text: "Фамилия"},
]

const Couriers = (props) => {
    const {users, getUsers, deleteUser, isLoading, totalPages, totalUsers, paginationParamsUsers, params, sortField, SearchUser} = props;

    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(null);
    const [page, setPage] = useState(null);
    const [sortConfig, setSortConfig] = useState(null);

    useEffect(() => {
        setPageSize(params.pageSize)
        setPage(params.page)
    }, [params]);

    useEffect(() => {
        if (sortConfig) {
            sortField(sortConfig)
        }
    }, [sortConfig])

    const onClickAddCourier = () => {
        navigate(ADD_COURIER_ROUTE);
    };

    const onClickAddProduct = () => {
        navigate(ADD_PRODUCT);
    };

    const onDeleteUser = async (e, id) => {
        await deleteUser(id);
        const res = await getUsers();
        if (res) {
            await paginationParamsUsers({
                pageSize: pageSize,
                page: res.totalPages - 1 < page ? res.totalPages - 1 : page
            });
        }
    };

    const isEmptyUsers = () => {
        const getUser = users.filter(f => f.role === "USER");
        return !getUser.length;
    };

    const onClickPage = async (e) => {
        await paginationParamsUsers({pageSize: pageSize, page: +e.target.id})
        await getUsers();
    }

    const onClickSize = async (e) => {
        await paginationParamsUsers({pageSize: +e.target.id, page: page});
        const res = await getUsers();
        if (res) {
            await paginationParamsUsers({
                pageSize: +e.target.id,
                page: res.totalPages - 1 < page ? res.totalPages - 1 : page
            });
        }
    }

    const requestSort = (id, key) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    return (
        <div className={`${classes.couriers} wrapper`}>
            <div className={classes.button__wrap}>
                <Button name="Добавить" onClick={onClickAddCourier}/>
            </div>
            <div className={classes.button__wrap}>
                <Button name="Товар в пути" onClick={onClickAddProduct}/>
            </div>
            <div className={classes.button__wrap}>
                <Search ActionSearch={SearchUser} placeholder={"Поиск"} />
            </div>
            <div className={classes.table__wrapper}>
                {!isLoading ? <table className={classes.table}>
                        <thead>
                        <tr className={classes.table__head}>
                            <td className={classes.table__column}>Телефон</td>
                            {statisticField.map(element =>
                                <td key={element.id}
                                    onClick={() => requestSort(element.id, element.sortKey)}
                                    className={`${classes.field__active} ${classes.table__column}`}
                                >
                                    {element.text}
                                </td>)
                            }
                            <td className={classes.table__column}>Имя</td>
                            <td className={classes.table__column}>Рейс</td>
                            <td className={classes.table__column}/>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(item => (
                                item.role === "USER" && <CouriersItem
                                    onDelete={onDeleteUser}
                                    key={item.id}
                                    id={item.id}
                                    phone={item.phone}
                                    last_name={item.last_name}
                                    first_name={item.first_name}
                                    route={item.route}
                                />
                            )
                        )}
                        </tbody>
                    </table> :
                    <div className="loader">
                        <Loader
                            type="ThreeDots"
                            color="#ff7800"
                            height={100}
                            width={100}
                        />
                    </div>
                }
            </div>
            {users.length ? <Pagination
                params={params}
                totalPages={totalPages}
                totalUsers={totalUsers}
                onClickPage={e => onClickPage(e)}
                onClickSize={e => onClickSize(e)}
            /> : null}
            {isEmptyUsers() && <div className={classes.empty}>Курьеры не найдены</div>}
        </div>
    );
};

const mapStateToProps = state => ({
    params: state.user.params,
    users: state.user.users,
    totalPages: state.user.totalPages,
    totalUsers: state.user.totalUsers,
    isLoading: state.user.isLoading
});

const mapDispatchToProps = (dispatch) => {
    return {
        deleteUser: (id) => dispatch(deleteUser(id)),
        getUsers: () => dispatch(getUsers()),
        paginationParamsUsers: ({pageSize, page}) => dispatch(paginationParamsUsers({pageSize, page})),
        sortField: (sortConfig) => dispatch(sortField(sortConfig)),
        SearchUser: (search_params) => dispatch(SearchUser(search_params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Couriers);
