import React, {useEffect, useState} from 'react';
import classes from "./ListProduct.module.scss"
import {connect} from "react-redux";
import ListProductItem from "./components/list-product-item/ListProductItem";
import {multiplication} from "../../../../utils/helpers/summHelper"
import {addMarker} from "../../../../store/map/action";
import {faLock, faTimes, faUnlock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {editIsShowProduct} from "../../../../store/products/action";

const ListProduct = (props) => {
    const {markers, products, addMarker, editIsShowProduct, close} = props;

    useEffect(() => {
        addMarker();
    }, []);

    const toggleLock = () => {
        const data = {
            id: products[0].id,
            isShow: products[0].isShow === false
        }
        editIsShowProduct(data)
    }

    return (
        <div className={classes["list-product"]}>
            <FontAwesomeIcon
                className={classes["list-product__lock"]}
                onClick={() => toggleLock()}
                icon={products[0].isShow ? faLock : faUnlock}
                size="1x"
            />
            <h4 className={classes["list-product--title"]}>Калькуляция стоимости товара В ПУТИ:</h4>
            {!products[0].isShow && <FontAwesomeIcon
                onClick={close}
                className={classes["list-product__close"]}
                icon={faTimes}
                size="1x"
            />}
            <table>
                <thead>
                <tr>
                    <td>Наименование</td>
                    <td>Кол-во, кг</td>
                    <td>Цена за 1 кг</td>
                    <td>Сумма в одном авто</td>
                </tr>
                </thead>
                <tbody>
                {products.length ? products.map(el => <ListProductItem key={el.id} product={el}/>) : null}
                <tr>
                    <td className={classes["list-product--not-border"]}></td>
                    <td className={classes["list-product--not-border"]}></td>
                    <td className={`${classes["list-product--not-border"]} ${classes["list-product--bold"]}`}>АВТО В
                        ПУТИ
                    </td>
                    <td className={`${classes["list-product--not-border"]} ${classes["list-product--bold"]}`}>СУММА В
                        ПУТИ
                    </td>
                </tr>
                <tr>
                    <td className={classes["list-product--not-border"]}></td>
                    <td className={classes["list-product--not-border"]}></td>
                    <td className={classes["list-product--bold"]}>{markers.length}</td>
                    <td className={`${classes["list-product--bold"]} ${classes["list-product--sum-on-way"]}`}>{products.length && (multiplication(products[0].amount, products[0].sum) * markers.length).toLocaleString()} &#8381;</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

const mapStateToProps = state => ({
    markers: state.map.markers,
    products: state.products.products
});

const mapDispatchToProps = (dispatch) => {
    return {
        addMarker: () => dispatch(addMarker()),
        editIsShowProduct: (data) => dispatch(editIsShowProduct(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ListProduct);
