import React, {useEffect, useState} from "react";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Expire = (props) => {
    const {delay, className, children} = props;
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setVisible(false);
        }, delay);

    }, [delay]);

    return visible ?
        <div className={className}>
            <FontAwesomeIcon
                onClick={() => setVisible(false)}
                className='modal__close'
                icon={faTimes}
                size="1x"
            />
            {children}
        </div>
        : false;
};

export default Expire;
