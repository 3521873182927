import axios from "axios";

const $Host = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL_DEVELOPMENT : process.env.REACT_APP_API_URL_PRODUCTION
});

const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
};

$Host.interceptors.request.use(authInterceptor);

class RestApi {
    async GET(req, params) {
        const {id, url} = req;

        const {data} = await $Host.get(id ? `${url}/${id}` : params ? `${url}/?pageSize=${params.pageSize}&page=${params.page}&direction=${params.direction}` : url);

        return data;
    };

    async POST(url, req) {
        const {data} = await $Host.post(url, req);
        return data;
    };

    async PUT(url, req) {
        const {data} = await $Host.put(url, req);
        return data;
    };

    async DELETE(url, id) {
        const {data} = await $Host.delete(`${url}/${id}`);
        return data;
    };
}

const Api = new RestApi();

export {
    $Host,
    Api
};
