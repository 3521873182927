import {Api} from "../../services/api";
import {Resource} from "../../services/ApiResources";
import {
    ADD_CUSTOM_SHIPMENT_STATISTICS, ADD_SHIPMENT_STATISTICS,
    CHANGE_LOADING,
    DELETE_SHIPMENT_STATISTICS,
    GET_SHIPMENT_STATISTICS,
    GET_SHIPMENT_STATISTICS_USER
} from "./actionType";

export const getShipmentStatistic = (params) => {
    return async (dispatch) => {
        dispatch({type: CHANGE_LOADING, payload: true});
        const url = params ? `${Resource.SHIPMENT_STATISTIC}?sort_name=${params.sort_name}&sort_type=${params.sort_type}&from=${params.from}&to=${params.to}`: Resource.SHIPMENT_STATISTIC
        const response = await Api.GET({url});
        dispatch({type: GET_SHIPMENT_STATISTICS, payload: response});
        dispatch({type: CHANGE_LOADING, payload: false});
    };
}

export const getShipmentUser = (userId) => {
    return async (dispatch) => {
        dispatch({type: CHANGE_LOADING, payload: true});
        const response = await Api.GET({url: `${Resource.SHIPMENT_STATISTIC}/${userId}`});
        dispatch({type: GET_SHIPMENT_STATISTICS_USER, payload: response});
        dispatch({type: CHANGE_LOADING, payload: false});
    };
}

export const addShipmentStatistic = (payload) => {
    return async (dispatch) => {
        dispatch({type: CHANGE_LOADING, payload: true});
        await Api.POST(Resource.SHIPMENT_STATISTIC, payload);
        dispatch({type: CHANGE_LOADING, payload: false});
    };
}

export const removeShipmentStatistic = (id) => {
    return async (dispatch) => {
        dispatch({type: CHANGE_LOADING, payload: true});
        await Api.DELETE(Resource.SHIPMENT_STATISTIC, id);
        dispatch({type: DELETE_SHIPMENT_STATISTICS, payload: id});
        dispatch({type: CHANGE_LOADING, payload: false});
    };
}

export const addCustomShipmentStatistic = (payload) => {
    return async (dispatch) => {
        dispatch({type: CHANGE_LOADING, payload: true});
        const result = await Api.POST(Resource.ADD_CUSTOM_SHIPMENT_STATISTIC, payload);
        dispatch({type: ADD_SHIPMENT_STATISTICS, payload: result});
        dispatch({type: CHANGE_LOADING, payload: false});
    };
}
