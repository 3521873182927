import {
    SET_IS_AUTH,
    SET_CURRENT_USER,
    LOGOUT,
    ERROR,
    GET_USERS,
    SUCCESS,
    DELETE_USER,
    TOTAL_PAGES,
    TOTAL_USERS,
    PARAMS,
    IS_FINISHED_ROUTES,
    CLEAR_FINISHED_THIS_USER,
    CLEAR_FINISHED_ROUTES_ALL,
    SORT_FIELD,
    SEARCH_USER, CHANGE_DIRECTION
} from "./actionType";
import {Resource} from "../../services/ApiResources";
import {Api} from "../../services/api";
import jwtDecode from "jwt-decode";
import {store} from "../../store";
import {socket} from "../../services/connectedSocket";

let defaultParams = {
    pageSize: 10,
    page: 0
}

export const loginUser = (email, password) => {
    return async (dispatch) => {
        try {
            dispatch({type: ERROR, payload: ''});
            const response = await Api.POST(Resource.LOGIN, {email, password});
            if (response) {
                localStorage.setItem('token', response.token);
                const userJwt = jwtDecode(response.token);
                const currentUser = await Api.GET({id: userJwt.id, url: Resource.CURRENT_USER});
                dispatch({type: SET_IS_AUTH, payload: true});
                dispatch({type: SET_CURRENT_USER, payload: currentUser.response});
            }
        } catch (e) {
            dispatch({type: ERROR, payload: e.response.data.message});
        }
    };
};

export const logout = () => {
    return (dispatch) => {
        localStorage.removeItem('token');
        dispatch({type: LOGOUT});
    };
};

export const checkAuth = () => {
    return async (dispatch) => {
        dispatch({type: PARAMS, payload: defaultParams});
        const tokenStore = localStorage.getItem('token');
        if (tokenStore) {
            const response = await Api.GET({url: Resource.AUTH});
            if (response) {
                const userJwt = jwtDecode(response.token);
                localStorage.setItem('token', response.token);
                const currentUser = await Api.GET({id: userJwt.id, url: Resource.CURRENT_USER});
                dispatch({type: SET_IS_AUTH, payload: true});
                dispatch({type: SET_CURRENT_USER, payload: currentUser.response});
            }
        } else {
            return false;
        }
    };
};

export const getUsers = () => {
    return async (dispatch) => {
        try {
            const params = store.getState().user.params;
            const direction = store.getState().user.direction;
            const newParams = {...params, direction}
            const response = await Api.GET({url: Resource.USERS}, newParams);
            dispatch({type: GET_USERS, payload: response.users.rows});
            dispatch({type: TOTAL_PAGES, payload: response.totalPages});
            dispatch({type: TOTAL_USERS, payload: response.users.count});
            return response
        } catch (e) {
            dispatch({type: ERROR, payload: e.response.data.message});
        }
    };
};

export const paginationParamsUsers = ({pageSize, page}) => {
    return async (dispatch) => {
        dispatch({type: PARAMS, payload: {pageSize: pageSize, page: page}})
    }
}

export const addUser = (data) => {
    return async (dispatch) => {
        try {
            const tokenStore = localStorage.getItem('token');
            const userJwt = jwtDecode(tokenStore);
            const response = await Api.POST(Resource.REGISTRATION, {...data, managerId: userJwt.id});
            dispatch({type: SUCCESS, payload: {...response, action: 'добавлен'}});
            return response
        } catch (e) {
            dispatch({type: ERROR, payload: e.response.data.message});
        }
    };
};

export const editAdmin = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: ERROR, payload: ''});
            const response = await Api.PUT(Resource.USER_EDIT, data);
            const currentUser = await Api.GET({id: response.id, url: Resource.CURRENT_USER});
            if (response) {
                dispatch({type: SET_CURRENT_USER, payload: currentUser.response});
                dispatch({type: SUCCESS, payload: {...response, action: 'изменен'}});
                return response;
            }
        } catch (e) {
            dispatch({type: ERROR, payload: e.response.data.message});
        }
    };
};

export const editCourier = (data) => {
    return async (dispatch) => {
        try {
            dispatch({type: ERROR, payload: ''});
            const response = await Api.PUT(Resource.USER_EDIT, data);
            dispatch({type: SUCCESS, payload: {...response, action: 'изменен'}});
            await socket.emit('update_user', {id: data.id, type: 'EDIT'});
            return response;
        } catch (e) {
            dispatch({type: ERROR, payload: e.response.data.message});
        }
    };
};

export const deleteUser = (id) => {
    return async (dispatch) => {
        const response = await Api.DELETE(Resource.USERS, id);
        dispatch({type: DELETE_USER, payload: id});
        dispatch({type: SUCCESS, payload: {...response, action: 'удален'}});
        await socket.emit('update_user', {id, type: 'DELETED'});
        return response;
    };
};

export const isFinishedRoute = () => {
    return async (dispatch) => {
        await socket.on('finished:route', async (data) => {
            if (data) {
                dispatch({type: IS_FINISHED_ROUTES, payload: data});
            }
        });
    };
}

export const deleteFinishedRoute = (id) => {
    return async (dispatch) => {
        const response = await Api.PUT(Resource.CLEAR_FINISHED_THIS_USER, {id});
        if (response) dispatch({type: CLEAR_FINISHED_THIS_USER, payload: response});
        await socket.emit('finished:route', {updateMarkers: true});
    };
}

export const deleteFinishedRoutesAll = () => {
    return async (dispatch) => {
        const response = await Api.PUT(Resource.CLEAR_FINISHED_ROUTES_ALL);
        if (response) dispatch({type: CLEAR_FINISHED_ROUTES_ALL, payload: response});
        await socket.emit('finished:route', {updateMarkers: true});
    };
}

export const sortField = (sortConfig) => {
    return async (dispatch) => {
        const params = store.getState().user.params;
        dispatch({type: CHANGE_DIRECTION, payload: sortConfig.direction});
        const direction = store.getState().user.direction;
        const newParams = {...params, direction}
        const response = await Api.GET({url: Resource.USERS}, newParams);
        dispatch({type: SORT_FIELD, payload: response.users.rows});
    }
}

export const SearchUser = (search_params) => {
    return async (dispatch) => {
        dispatch({type: PARAMS, payload: {pageSize: 999999, page: 0}})
        const params = store.getState().user.params;
        const response = await Api.GET({url: Resource.USERS}, params);
        dispatch({type: TOTAL_PAGES, payload: response.totalPages});

        const filteredUsersLastName = response.users.rows.filter(user => {
            if (user.last_name.toLowerCase().includes(search_params.toLowerCase())) {
                return user.last_name.toLowerCase().includes(search_params.toLowerCase());
            } else {
                return user.first_name.toLowerCase().includes(search_params.toLowerCase());
            }
        })

        dispatch({type: SEARCH_USER, payload: filteredUsersLastName});
    }
}

export const uploadAvatar = (formData) => {
    return async (dispatch) => {
        try {
            const response = await Api.POST(Resource.UPLOAD_AVATAR, formData);
            return response.fileName;
        } catch (e) {
            if (typeof e.response.data.message === 'string') dispatch({type: ERROR, payload: e.response.data.message});
        }
    }
}
