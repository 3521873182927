import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {COURIERS_ROUTE} from "../../../utils/constants/routes.constants";
import classes from "./AddProducts.module.scss";
import Form from "../../../components/form/Form";
import Select from "../../../components/form/select/Select";
import InputForm from "../../../components/form/input/InputForm";
import {createProduct, deleteProduct, editProduct, getProducts} from "../../../store/products/action";

const AddProducts = (props) => {
    const {createProduct, products, getProducts, editProduct, deleteProduct} = props;
    const [data, setData] = useState({});
    const [product, setProduct] = useState({});
    const [productName, setProductName] = useState('Навага');
    const [amount, setAmount] = useState('');
    const [sum, setSum] = useState('');

    useEffect(() => {
        if (products.length) {
            const newProduct = products.reduce((acc, item) => {
                    acc = item;
                    return acc
            }, {});
            setProduct(newProduct)
        } else {
            setProduct([]);
            setProductName('Навага');
            setAmount('');
            setSum('');
        }
    }, [products]);


    useEffect(() => {
        getProducts();
    }, []);

    useEffect(() => {
        setData({
            name: productName,
            amount: amount,
            sum: sum
        })

        if (products.length) {
            setData({
                id: product.id,
                name: productName,
                amount: amount,
                sum: sum
            })
        }
    }, [productName, amount, sum])

    useEffect(() => {
        if (Object.keys(product).length !== 0) {
            if (product.name) setProductName(product.name);
            if (product.amount) setAmount(product.amount);
            if (product.sum) setSum(product.sum);
        }
    }, [product])

    const onAmount = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            setAmount(e.target.value.slice(0, 6));
        }
    }

    const onSum = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            setSum(e.target.value.slice(0, 6));
        }
    }

    return (
        <div className={classes["add-products"]}>
            <h2 className={classes["add-products--title"]}>Добавить/изменить продукт</h2>
            <Form
                data={data}
                path={COURIERS_ROUTE}
                requestSubmit={products.length ? editProduct : createProduct}
                nameButton={products.length ? 'Изменить' : 'Сохранить'}
                isDeleteButton={products.length}
                requestDelete={deleteProduct}
            >
                <Select name="product"
                        style={{minWidth: "33%"}}
                        label="Товар"
                        value={productName}
                        onChange={e => setProductName(e.target.value)}>
                    <option value="Навага">Навага</option>
                    <option value="Мойва">Мойва</option>
                    <option value="Сельдь">Сельдь</option>
                    <option value="Лосось">Лосось</option>
                    <option value="ИКРА Лососевая">ИКРА Лососевая</option>
                </Select>
                <InputForm name="amount"
                           style={{minWidth: "33%"}}
                           type="text"
                           placeholder="Введите кол-во, кг"
                           label="Кол-во, кг"
                           value={amount}
                           onChange={onAmount}
                />
                <InputForm name="sum"
                           style={{minWidth: "33%"}}
                           type="text"
                           placeholder="Введите цену"
                           label="Цена за 1 кг"
                           value={sum}
                           onChange={onSum}
                />
            </Form>

        </div>
    );
};

const mapStateToProps = state => ({
    products: state.products.products
});

const mapDispatchToProps = (dispatch) => {
    return {
        createProduct: (data) => dispatch(createProduct(data)),
        getProducts: () => dispatch(getProducts()),
        editProduct: (data) => dispatch(editProduct(data)),
        deleteProduct: (id) => dispatch(deleteProduct(id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProducts);
