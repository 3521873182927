import React from "react";
import classes from "./Button.module.scss";
import Loader from "react-loader-spinner";

const Button = (props) => {
    const {name, className, isLoading, onClick, style, ...rest} = props;

    return (
        <button
            {...rest}
            type="button"
            onClick={onClick}
            className={className ? `${classes.button} ${className}` : classes.button}
            style={style}>
            {!isLoading && name}
            {isLoading && <Loader
                type="ThreeDots"
                color="#ffffff"
                height={10}
                width={20}
            />}
        </button>
    );
};

export default Button;
